import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";

const BreadFinancialErrorPage = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    return (
        <Background>
            <LogoAndTimer />
            <Stack gap={5} className='text-center mt-5'>
                <p>{t("breadfinancial.error")}</p>
                <p>{token}</p>
            </Stack>
        </Background>
    );
};

export default BreadFinancialErrorPage;
