import { CoreModule } from "dynamsoft-core";
import { LicenseManager } from "dynamsoft-license";
import "dynamsoft-barcode-reader";
import "dynamsoft-document-normalizer";
import "dynamsoft-capture-vision-router";

const licenseVar = window.__RUNTIME_CONFIG__.REACT_APP_DYNAMSOFT_LICENSE || "";

LicenseManager.initLicense(licenseVar);

const dynamsoftCdnPaths = {
    std: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.2.10/dist/",
    dip: "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.2.30/dist/",
    core: "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.2.30/dist/",
    license: "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.2.21/dist/",
    cvr: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.2.30/dist/",
    dbr: "https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.2.10/dist/",
    dce: "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.3/dist/",
    ddn: "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.2.10/dist/",
};

CoreModule.engineResourcePaths = dynamsoftCdnPaths;

CoreModule.loadWasm(["DBR", "DDN"]).catch((ex: any) => {
    let errMsg;
    if (ex.message?.includes("network connection error")) {
        errMsg =
            "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
    } else {
        errMsg = ex.message || ex;
    }
    alert(errMsg);
});
