import { Button, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { setUserObject } from "../../../redux/slices/user.slice.ts";
import Background from "../../../components/Background/Background.tsx";
import { useEffect } from "react";
import Logo from "../../../components/Logo/Logo.tsx";
import { useLogEventMutation } from "../../../api/api.ts";
import createLogEventBody from "../../../utils/createLogEventBody.js";

const AcmeLandingPage = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(search);
    const dispatch = useDispatch();

    const [logEvent] = useLogEventMutation();

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.openTextLink.status));
    }, []);

    const removeProcessRequirements = (route: string, reqsToRemove: any) => {
        const allCaptureReqs = ["front", "barcode", "selfie"];
        navigate(`/${route}?${urlParams.toString()}`);
        const updatedCaptureReqs = allCaptureReqs.filter((req) => !reqsToRemove.includes(req));
        dispatch(setUserObject({ captureRequirements: updatedCaptureReqs }));
    };

    return (
        <Background>
            <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                <Logo />
                <div className='d-flex flex-column text-center mb-5 mt-5'>
                    <h1 className='fw-bold'>Welcome to the Acme Company Application!</h1>
                    <h3 className='mt-5'>You will now verify your ID with Intellicheck®.</h3>
                </div>
                <div className='mb-5' />
                <Stack gap={3}>
                    <Button variant='success' onClick={() => removeProcessRequirements("barcode", ["front", "selfie"])}>
                        Verify Barcode
                    </Button>
                    <Button variant='success' onClick={() => removeProcessRequirements("front", ["selfie"])}>
                        Verify ID
                    </Button>
                    <Button variant='success' onClick={() => removeProcessRequirements("front", [])}>
                        Validate ID with Selfie
                    </Button>
                </Stack>
            </div>
        </Background>
    );
};

export default AcmeLandingPage;
