import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useIngestBarcodeDataMutation } from "../../../api/api.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import Background from "../../../components/Background/Background.tsx";
import Logo from "../../../components/Logo/Logo.tsx";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorPage from "../../ErrorPages/ErrorPage.tsx";
import ErrorBoundaryWrapper from "../../../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";

const IDNDemoResultsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [caughtErrorMessage, setCaughtErrorMessage] = useState("");
    const [ingestBarcodeData, barcodeResponse] = useIngestBarcodeDataMutation();
    const errorCaught = useRef<boolean>(false);
    const hasSubmittedBarcode = useRef<boolean>(false);
    const idnDemoBarcodeResults = useSelector((state: RootState) => state.demo.barcodeScanResults);

    useEffect(() => {
        if (barcodeResponse.isError) {
            navigate("/error");
        }
    }, [barcodeResponse]);

    useEffect(() => {
        if (caughtErrorMessage) {
            navigate("/error");
        }
    }, [caughtErrorMessage]);

    if (idnDemoBarcodeResults) {
        try {
            const idnDemoParsed = JSON.parse(idnDemoBarcodeResults);

            const {
                private_data: {
                    result: { FirstName, LastName, Address1, City, DateOfBirth, PostalCode, State },
                },
            } = idnDemoParsed;

            const results = [
                {
                    label: t("resultsPage.firstName"),
                    value: FirstName,
                },
                {
                    label: t("resultsPage.lastName"),
                    value: LastName,
                },
                {
                    label: t("resultsPage.dob"),
                    value: DateOfBirth,
                },
                {
                    label: t("resultsPage.address"),
                    value: Address1,
                },
                {
                    label: t("resultsPage.city"),
                    value: City,
                },
                {
                    label: t("resultsPage.state"),
                    value: State,
                },
                {
                    label: t("resultsPage.zip"),
                    value: PostalCode,
                },
            ];

            return (
                <ErrorBoundaryWrapper>
                    <Background>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <Logo />
                            <Alert variant='success' className='mt-3 w-100 text-center'>
                                <>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='#19c567'
                                        className='me-2'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                    </svg>
                                    Success
                                </>
                            </Alert>
                            <p className='text-left'>{t("resultsPage.text1")}</p>

                            <div className='d-flex flex-column vw-100 align-items-center'>
                                {results.map((value: any, index: number) => (
                                    <div key={`${index}_result`} className='d-flex flex-row w-100 gap-3 px-1'>
                                        <div className='d-flex col-5 justify-content-end align-items-center'>
                                            <label htmlFor='usr' className='form-label fw-bold'>
                                                {value?.label}:{" "}
                                            </label>
                                        </div>
                                        <div className='d-flex col-7'>
                                            <label className='form-label text-start' id='usr'>
                                                {value?.value}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <Link reloadDocument to={`/?&reload=true`}>
                                <Button className='mt-3' variant='primary'>
                                    {t("resultsPage.newSim")}
                                </Button>
                            </Link>
                            <>
                                <p className='mt-3 ms-2'>{t("resultsPage.simResults")}</p>
                                <p className='my-3 ms-2'>{t("simulatorData")}</p>
                            </>
                        </div>
                    </Background>
                </ErrorBoundaryWrapper>
            );
        } catch (ex: any) {
            if (!errorCaught.current) {
                errorCaught.current = true;
                setCaughtErrorMessage(`IDNSiteResultsPage error: ${ex?.message}`);
            }
            return <ErrorPage />;
        }
    } else {
        return <ErrorPage />;
    }
};

export default IDNDemoResultsPage;
