import React from "react";
import { useLocation } from "react-router-dom";
import ErrorPage from "./pages/ErrorPages/ErrorPage.tsx";
import { getCompanyConfig } from "./utils/getCompanyConfig.ts";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store.ts";
import CompletePage from "./pages/CompletePage.tsx";
import { useTranslation } from "react-i18next";

type OwnProps = {
    children: React.ReactNode;
};

const StylesheetWrapper: React.FC<OwnProps> = ({ children }) => {
    const location = useLocation();
    const search = location.search;
    const urlParams = new URLSearchParams(search);
    const { completed } = useSelector((state: RootState) => state.user);

    const { i18n } = useTranslation();
    const language = urlParams.get("language");
    const languageInitialized = React.useRef<boolean>(false);

    React.useEffect(() => {
        if (language && !languageInitialized.current) {
            // navigator.language // We can add browser-based language later
            i18n.changeLanguage(language);
            languageInitialized.current = true;
        }
    }, []);

    const css = getCompanyConfig("css");
    var fileref = document.createElement("link");
    fileref.rel = "stylesheet";
    fileref.type = "text/css";
    fileref.href = css;
    fileref.className = "bootstrap_css";
    const appendedCssElements = document.getElementsByClassName("bootstrap_css");
    const elementsArray = Array.from(appendedCssElements);

    if (elementsArray?.length && elementsArray?.length > 1) {
        elementsArray.forEach((element, index) => {
            if (index < elementsArray?.length - 1) {
                element.remove();
            }
        });
    }

    document.getElementsByTagName("head")[0].appendChild(fileref);
    if (!urlParams.get("token") && !urlParams.get("userToken")) {
        children = <ErrorPage />;
    }
    if (completed) {
        children = <CompletePage />;
    }
    return <>{children}</>;
};

export default StylesheetWrapper;
