import { companyMap } from "../companyMap.tsx";

export const getCompanyConfig = (component: string) => {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);

    let routerVersion = urlParams.get("version");
    const defaultEnvVersion = window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_EXPERIENCE;

    if (!routerVersion && defaultEnvVersion) {
        urlParams.append("version", defaultEnvVersion);
        routerVersion = defaultEnvVersion;
    }

    const companies = Object.keys(companyMap);

    const companyData = companyMap[routerVersion && companies.includes(routerVersion) ? routerVersion : "default"];

    const componentToReturn: any = (companyData as any)[component];

    return componentToReturn;
};
