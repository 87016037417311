const calculateUTCExpirationTime = (millisecondsUntilExpiration) => {
    const currentTime = new Date().getTime();

    const expirationTimeInMs = currentTime + millisecondsUntilExpiration;

    const expirationTime = new Date(expirationTimeInMs).toISOString();

    return expirationTime;
};

export default calculateUTCExpirationTime;
