import React from "react";
import Background from "../../../../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LogoAndTimer from "../../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Stack } from "react-bootstrap";

const AOLCommunitiesErrorPage = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    return (
        <Background>
            <LogoAndTimer />
            <div className='vh-100 d-flex mb-5'>
                <Stack gap={5} className='d-flex justify-content-center text-center mb-5'>
                    <h3>{t("errorPage")}</h3>
                    <p>{token}</p>
                </Stack>
            </div>
        </Background>
    );
};

export default AOLCommunitiesErrorPage;
