/**
 * The `YahooEUCompletePage` component represents a page indicating the completion of a task or process for Yahoo.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling.
 *
 * @component
 * @example
 * // Import the YahooEUCompletePage component in your application
 * import YahooEUCompletePage from './YahooEUCompletePage';
 *
 * // Render the YahooEUCompletePage component within a Route
 * <Route path='/complete' element={<YahooEUCompletePage />} />
 *
 * // When navigating to '/complete', the YahooEUCompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */
import { Stack } from "react-bootstrap";
import YahooEUCheckMark from "../../../../assets/Yahoo/YahooEUCheckMark.tsx";
import { useLogEventMutation } from "../../../../api/api.ts";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import createLogEventBody from "../../../../utils/createLogEventBody.js";
import CustomerJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";

const YahooEUCompletePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isSubmitFrontSuccess = location?.state?.isSubmitFrontSuccess;
    const [logEvent] = useLogEventMutation();

    useEffect(() => {
        if (isSubmitFrontSuccess) {
            try {
                logEvent(createLogEventBody(CustomerJourneyCodes.complete.navToPage.status));
            } catch (error) {
                logEvent(createLogEventBody(CustomerJourneyCodes.errors[404].navToPage.status, "Something went wrong submitting the front image."));
            }
        }
    }, [isSubmitFrontSuccess]);

    return (
        <>
            <Stack gap={5} className='align-items-center text-center'>
                <h1 className='fw-bolder mb-5'>{t("yahooEU.complete")}</h1>
                <YahooEUCheckMark />
                <p className='fst-italic fw-bold col-9 col-md-5 col-lg-4 col-xl-3 fw-bold fs-5 mt-5'>{t("yahooEU.completeMessage")}</p>
            </Stack>
        </>
    );
};

export default YahooEUCompletePage;
