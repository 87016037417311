/**
 * The `TruistCompletePage` component represents a page indicating the completion of a task or process for Truist.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling and navigation
 * is handled using the `useNavigate` hook from React Router.
 *
 * @component
 * @example
 * // Import the TruistCompletePage component in your application
 * import TruistCompletePage from './TruistCompletePage';
 *
 * // Render the TruistCompletePage component within a Route
 * <Route path='/complete' element={<TruistCompletePage />} />
 *
 * // When navigating to '/complete', the TruistCompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */

import { Stack } from "react-bootstrap";
import TruistCheckMark from "../../../assets/Truist/TruistCheckMark.tsx";
import { useTranslation } from "react-i18next";

const TruistCompletePage = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={5} className='align-items-center mt-4'>
            <h1 className='text-dark'>{t("truist.completeText1")}</h1>

            <TruistCheckMark />

            <Stack gap={2}>
                <h1 className='fw-bold text-center text-primary'>{t("truist.completeText2")}</h1>
                <ul className='text-start' role='list'>
                    <Stack gap={2}>
                        <li
                            role='listitem'
                            aria-label='If you started the application on mobile, expect your page to auto-refresh to continue with next steps'
                        >
                            <span className='fw-bold'>{t("truist.completeLineItemMobile1")}</span>
                            {t("truist.completeLineItemMobile2")}
                        </li>
                        <li
                            role='listitem'
                            aria-label='If you started the application on a desktop computer, please return to it and continue with the next steps.'
                        >
                            <span className='fw-bold'>{t("truist.completeLineItemDesktop1")}</span>
                            {t("truist.completeLineItemDesktop2")}
                        </li>
                    </Stack>
                </ul>
            </Stack>
        </Stack>
    );
};

export default TruistCompletePage;
