import React from "react";
import { Trans, useTranslation } from "react-i18next";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Button, Col, Row, Stack } from "react-bootstrap";
import createLogEventBody from "../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useNavigate } from "react-router";
import { useLogEventMutation } from "../../../api/api.ts";

const AcimaBiometricsDeniedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [logEvent] = useLogEventMutation();
    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.biometricsDenied.clickBack.status));
        navigate(-1);
    };

    return (
        <>
            <LogoAndTimer />
            <Row className='vh-100 justify-content-between'>
                <Col xs={12} className='mb-5'>
                    <Stack gap={3}>
                        <h1 className='mb-3 text-center'>{t("biometricsDeniedPage.question")}</h1>
                        <div>
                            <p>{t("biometricsDeniedPage.description")}</p>
                            <ul>
                                <li>
                                    <Trans i18nKey={"acima.biometricsDenied1"} />
                                </li>
                                <li>
                                    <Trans i18nKey={"acima.biometricsDenied2"} />
                                </li>
                            </ul>
                        </div>
                    </Stack>
                </Col>
                <Col xs={12} className='mt-4 mb-5'>
                    <Button className='w-100' onClick={clickBack}>
                        {t("biometricsDeniedPage.back")}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default AcimaBiometricsDeniedPage;
