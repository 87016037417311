import { Routes, Route, useLocation } from "react-router-dom";
import MainOutlet from "../MainOutlet.tsx";
import OldOutlet from "../OldOutlet.tsx";
import FrontPage from "../pages/FrontPage.tsx";
import SelfieCapture from "../components/SelfieCapture/AuthIdComponent.tsx";
import ErrorPage from "../pages/ErrorPages/ErrorPage.tsx";
import TimeoutPage from "../pages/ErrorPages/TimeoutPage.tsx";
import FourZeroFourPage from "../pages/ErrorPages/FourZeroFourPage.tsx";
import BiometricsDeniedPage from "../pages/ErrorPages/BiometricsDeniedPage.tsx";
import CameraPermissionsDenied from "../pages/ErrorPages/CameraPermissionsDeniedPage.tsx";
import BarcodePage from "../pages/BarcodePage.tsx";
import IDNDemoLandingPage from "../pages/custom/IDNDemo/IDNDemoLandingPage.tsx";
import IDNDemoResultsPage from "../pages/custom/IDNDemo/IDNDemoResultsPage.tsx";

const IDNDemoRouter = (): JSX.Element => {
    const currLocation = useLocation();

    return (
        <>
            <Routes location={currLocation} key={currLocation.pathname}>
                <Route element={<MainOutlet />}>
                    <Route path='/' element={<IDNDemoLandingPage />} />

                    <Route path='/selfie' element={<SelfieCapture />} />

                    <Route path='/error' element={<ErrorPage />} />

                    <Route path='/token-expired' element={<TimeoutPage />} />

                    <Route path='/biometrics-denied' element={<BiometricsDeniedPage />} />

                    <Route path='/camera-denied' element={<CameraPermissionsDenied />} />

                    <Route path='/*' element={<FourZeroFourPage />} />

                    <Route path='/results' element={<IDNDemoResultsPage />} />
                </Route>

                <Route element={<OldOutlet />}>
                    <Route path='/front' element={<FrontPage />} />
                    <Route path='/barcode' element={<BarcodePage />} />
                </Route>
            </Routes>
        </>
    );
};

export default IDNDemoRouter;
