import React from "react";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CommissionBeforeClosingLandingPage = () => {
    const { t } = useTranslation();
    return (
        <Stack gap={1} className='text-center mt-4'>
            <h1 className='mt-3 mt-md-5 mb-md-5'>{t("commissionbeforeclosing.landingText1")}</h1>
            <p className='mt-3 mb-3 mt-md-5 mb-md-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-3 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text3")}</p>
        </Stack>
    );
};

export default CommissionBeforeClosingLandingPage;
