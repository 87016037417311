/**
 * The `YahooEULandingPage` component serves as the landing or home page of the Yahoo application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the YahooEULandingPage component in your application
 * import YahooEULandingPage from './YahooEULandingPage';
 *
 * // Render the YahooEULandingPage component within a Route
 * <Route path='/' element={<YahooEULandingPage />} />
 *
 * // When navigating to the root URL ('/'), the YahooEULandingPage component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */
import { Button, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redux/store.ts";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";
import { useLogEventMutation, useUpdateDocumentTypeMutation } from "../../../../api/api.ts";
import { setUserObject } from "../../../../redux/slices/user.slice.ts";
import { useEffect } from "react";
import createLogEventBody from "../../../../utils/createLogEventBody.js";

const YahooEULandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();
    const dispatch = useDispatch();
    const [updateDocumentType, updateDocTypeResponse] = useUpdateDocumentTypeMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    const selectPassport = () => {
        // newDocumentType value must coincide with JTs ACCEPTABLE_DOCUMENT_TYPES
        updateDocumentType({ newDocumentType: "passport" });
        localStorage.setItem("docType", "Passport");
    };

    const selectPassportCard = () => {
        // newDocumentType value must coincide with JTs ACCEPTABLE_DOCUMENT_TYPES
        updateDocumentType({ newDocumentType: "other" });
        localStorage.setItem("docType", "Passport Card");
    };

    const clickIntellicheckPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    const clickYahooEUPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    useEffect(() => {
        if (updateDocTypeResponse.isSuccess) {
            const newCaptureRequirements = updateDocTypeResponse?.data.payload?.captureRequirements;
            if (newCaptureRequirements) {
                dispatch(setUserObject({ captureRequirements: newCaptureRequirements }));
            }

            logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
            navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
        }
    }, [updateDocTypeResponse.isSuccess]);

    return (
        <>
            <Stack gap={2} className='align-items-center'>
                <h1 className='fw-bolder fs-2 mt-2'>{t("yahooEU.welcome")}</h1>
                <div className='align-items-center text-center col-10 col-md-7 col-lg-6 col-xl-5 fs-3'>
                    <>
                        <p>{t("yahooEU.welcome1")}</p>
                        <p>{t("yahooEU.welcome2")}</p>
                        <p>{t("yahooEU.welcome3")}</p>
                    </>
                    <div className='mt-5'>
                        <Button className='col-11 col-md-9 col-lg-8 col-xl-6 mb-3' variant='primary' onClick={selectPassport}>
                            {t("yahooEU.passportButton")}
                        </Button>
                        <Button className='col-11 col-md-9 col-lg-8 col-xl-6 mb-3' variant='primary' onClick={selectPassportCard}>
                            {t("yahooEU.passportCardButton")}
                        </Button>
                        <p className='fs-6'>
                            {t("yahooEU.acknowlege")}{" "}
                            <a
                                href='https://www.intellicheck.com/privacy-policy/'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickIntellicheckPrivacyPolicy}
                            >
                                {t("yahooEU.privacyPolicyIntellicheck")}
                            </a>{" "}
                            {t("yahooEU.andIn")}{" "}
                            <a
                                href='https://legal.yahoo.com/ie/en/yahoo/privacy/index.html'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickYahooEUPrivacyPolicy}
                            >
                                {t("yahooEU.privacyPolicyYahoo")}
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default YahooEULandingPage;
