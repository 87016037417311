import { useTranslation } from "react-i18next";

const AcimaCompletePage = () => {
    const { t } = useTranslation();
    return (
        <>
            <h1 className='text-center'>{t("completePage.submitted")}</h1>
            <p>
                {t("completePage.complete")} {t("completePage.close")}
            </p>
        </>
    );
};
export default AcimaCompletePage;
