import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";

const Logo = () => {
    const logo = getCompanyConfig("logo");
    const ariaLogo = getCompanyConfig("ariaLogo");

    return (
        // Brandy approved for inline style
        logo && <img className='mw-100' style={{ maxHeight: "90px" }} src={logo} alt='Logo' aria-label={ariaLogo} />
    );
};

export default Logo;
