import { Button, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Background from "../../../components/Background/Background.tsx";
import Logo from "../../../components/Logo/Logo.tsx";

const AcmeCompletePage = () => {
    const navigate = useNavigate();
    const rootRouteHandler = () => {
        navigate(`/`);
        window.location.reload();
    };

    return (
        <Background>
            <div className='d-flex justify-content-center mt-3'>
                <Logo />
            </div>
            <Stack gap={5} className='d-flex text-center mt-5 justify-content-start align-items-center'>
                <h1 className='text-success fw-bold'>Congratulations!</h1>
                <h3 className='fw-bold'>Your ID validation has been submitted. You will be notified once your application has been approved!</h3>
                <Button variant='success' className='w-25' onClick={() => rootRouteHandler()}>
                    End
                </Button>
            </Stack>
        </Background>
    );
};

export default AcmeCompletePage;
