/**
 * The `Landing` component serves as the landing or home page of the application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the Landing component in your application
 * import Landing from './Landing';
 *
 * // Render the Landing component within a Route
 * <Route path='/' element={<Landing />} />
 *
 * // When navigating to the root URL ('/'), the Landing component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */
import { Button, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import selfieImage from "../assets/selfie-image.png";
import { RootState } from "../redux/store.ts";
import { useSelector } from "react-redux";
import Background from "../components/Background/Background.tsx";
import { useEffect } from "react";
import { getCompanyConfig } from "../utils/getCompanyConfig.ts";
import { useTranslation } from "react-i18next";

import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../components/LogoAndTimer/LogoAndTimer.tsx";
import { useLogEventMutation } from "../api/api.ts";
import createLogEventBody from "../utils/createLogEventBody.js";

const LandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;

    const landingImages = getCompanyConfig("landingImages");
    const landingComponent = getCompanyConfig("landingComponent");
    const hideDefaultLanding = getCompanyConfig("hideDefaultLanding");
    const ariaLandingImg = getCompanyConfig("ariaLandingImg");

    const landingImage = landingImages ? landingImages[0] : selfieImage;

    const [logEvent] = useLogEventMutation();

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.openTextLink.status));
    }, []);

    const clickStart = () => {
        // ("User clicked start data collection");
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <Background>
            <LogoAndTimer />
            {landingComponent ? (
                landingComponent
            ) : (
                <Row className='my-4'>
                    <img
                        className='col-xs-12 col-sm-10 col-md-7 w-50 d-block mx-auto'
                        src={landingImage}
                        alt={ariaLandingImg}
                        aria-label={ariaLandingImg}
                    />
                </Row>
            )}
            {hideDefaultLanding ? (
                <></>
            ) : (
                <Stack gap={2} className='mt-2 mt-md-3 d-flex justify-content-center align-items-center text-center'>
                    <Button variant='primary' onClick={clickStart} className='col-7 col-md-5 p-2 p-md-3'>
                        {t("landingPage.start")}
                    </Button>
                    <div>
                        <h1 className='fw-bold'>
                            {captureReqs.length} - {t("landingPage.step")}
                        </h1>
                        <h2 className='text-center fw-bold'>{t("landingPage.identityValidation")}</h2>
                    </div>
                    <p>
                        {t("landingPage.privacyPolicyMessage")}{" "}
                        <a
                            onClick={() => logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status))}
                            href={t("landingPage.privacyPolicyLink")}
                            target='_blank'
                        >
                            {t("landingPage.privacyPolicy")}
                        </a>
                        .
                    </p>
                </Stack>
            )}
        </Background>
    );
};

export default LandingPage;
