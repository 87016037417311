import React, { useEffect } from "react";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI.tsx";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";
import { useLogEventMutation } from "../api/api.ts";
import createLogEventBody from "../utils/createLogEventBody.js";

const BackPage = () => {
    const [logEvent] = useLogEventMutation();

    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.captureBack.navToPage.status));
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <VideoNormalizerUI currScanSide={"Back"} />
        </ErrorBoundaryWrapper>
    );
};
export default BackPage;
