import { TrackJS } from "trackjs";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store.ts";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundaryWrapper from "./components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import StylesheetWrapper from "./StylesheetWrapper.tsx";

import App from "./App.tsx";
import "./i18n/config.ts";
import AcmeApp from "./AcmeApp.tsx";
import IDNDemoApp from "./IDNDemoApp.tsx";

if (window.__RUNTIME_CONFIG__.REACT_APP_TRACK_JS_ON?.toLowerCase() === "true") {
    TrackJS.install({
        token: window.__RUNTIME_CONFIG__.REACT_APP_TRACKJS_TOKEN || "",
        application: window.__RUNTIME_CONFIG__.REACT_APP_TRACKJS_APPLICATION || "capture",
        console: { enabled: false },
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_EXPERIENCE === "acme") {
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <AcmeApp />
            </Router>
        </Provider>,
        // </React.StrictMode>
    );
} else if (window.__RUNTIME_CONFIG__.REACT_APP_DEFAULT_EXPERIENCE === "idndemo") {
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ErrorBoundaryWrapper>
                    <IDNDemoApp />
                </ErrorBoundaryWrapper>
            </Router>
        </Provider>,
        // </React.StrictMode>
    );
} else {
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ErrorBoundaryWrapper>
                    <StylesheetWrapper>
                        <App />
                    </StylesheetWrapper>
                </ErrorBoundaryWrapper>
            </Router>
        </Provider>,
        // </React.StrictMode>
    );
}
