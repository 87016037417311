import Logo from "../Logo/Logo.tsx";
import { Col, Row } from "react-bootstrap";
import ExpirationTimer from "../ExpirationTimer/ExpirationTimer.tsx";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";

const LogoAndTimer = () => {
    const logo = getCompanyConfig("logo");
    const currUrlPath = window.location.pathname;
    const hideCountdownTimer = getCompanyConfig("hideCountdownTimer");
    const shouldRender = !hideCountdownTimer && currUrlPath !== "/complete";

    return (
        (logo || shouldRender) && (
            <Row className={`p-2 my-2 my-md-3 align-items-center ${shouldRender ? "justify-content-between" : "justify-content-center"}`}>
                {logo && (
                    <Col xs={shouldRender ? 8 : 12} lg={shouldRender ? 5 : 7} className={shouldRender ? "" : "d-flex justify-content-center"}>
                        <Logo />
                    </Col>
                )}
                <Col xs={"auto"}>
                    <ExpirationTimer shouldRender={shouldRender} />
                </Col>
            </Row>
        )
    );
};

export default LogoAndTimer;
