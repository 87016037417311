import CertifIDSpinner from "../../../assets/CertifID/CertifIDSpinner.svg";
import "./CertifIDCompletePage.css";

const CertifIDCompletePage = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='loadingPage'>
                <div className='logo-spinner'>
                    <div className='circle-border'>
                        <div className='circle-core'>
                            <div className='circle-inner'></div>
                        </div>
                    </div>
                    <div className='icon'>
                        <img src={CertifIDSpinner} alt='CertifID Spinner' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertifIDCompletePage;
