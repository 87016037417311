import { Outlet } from "react-router-dom";

const OldOutlet = () => {
    return (
        <div className='d-flex flex-column h-100 vw-100'>
            <Outlet />
        </div>
    );
};

export default OldOutlet;
