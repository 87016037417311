/**
 * Description: Defines a Redux slice for app-related state management.
 * This slice handles app specific state which is separate from the user.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

/**
 * Defined enumeration for ScanSides
 */
export enum ScanSideEnum {
    None = "none",
    Front = "front",
    Back = "back",
    Barcode = "barcode",
    Selfie = "selfie",
}

/**
 * Set initial App state
 */
type AppState = {
    routerVersion: string;
    sequenceNumber: number;
    /** hasCompleted values update useNavigateCapReq to determine which capture requirement paths to remove from nav array */
    hasCompletedFront: boolean;
    hasCompletedBack: boolean;
    hasCompletedBarcode: boolean;
    hasCompletedSelfie: boolean;
    hasLoadedOpenCV: boolean;
};

const initialState: AppState = {
    routerVersion: "",
    sequenceNumber: 0,
    hasCompletedFront: false,
    hasCompletedBack: false,
    hasCompletedBarcode: false,
    hasCompletedSelfie: false,
    hasLoadedOpenCV: false,
};

/**
 * Create the App slice
 */
const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        incrementSequenceNumber: (state) => {
            state = {
                ...state,
                sequenceNumber: (state.sequenceNumber += 1),
            };
            return state;
        },
        updateRouterVersion: (state, action: PayloadAction<string>) => {
            state = {
                ...state,
                routerVersion: action.payload,
            };
            return state;
        },
        updateHasCompletedFront: (state, action: PayloadAction<boolean>) => {
            state = {
                ...state,
                hasCompletedFront: action.payload,
            };
            return state;
        },
        toggleHasLoadedOpenCV: (state, action: PayloadAction<boolean>) => {
            state = {
                ...state,
                hasLoadedOpenCV: action.payload,
            };
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    },
});

/**
 * Export App slice actions
 */
export const {
    updateRouterVersion,
    updateHasCompletedFront,
    incrementSequenceNumber,
    toggleHasLoadedOpenCV,
    reset: resetAppState,
} = appSlice.actions;

/**
 * Export the reducer
 */
export default appSlice.reducer;
