import { useTranslation } from "react-i18next";
import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";

const BreadFinancialTimeoutPage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <LogoAndTimer />
            <h3 className='text-center mt-5'>{t("breadfinancial.timeout")}</h3>
        </Background>
    );
};

export default BreadFinancialTimeoutPage;
