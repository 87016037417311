import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MoneyTreeLandingPage = () => {
    const { t } = useTranslation();

    return (
        <Row className='my-5 justify-content-center'>
            <Col xs={12} className='mb-5'>
                <h1 className='text-center fw-bold text-primary'>{t("moneytree.landingHeader")}</h1>
            </Col>
            <Col xs={10}>
                <p>{t("moneytree.landingBodyText")}</p>
                <ul>
                    <li>{t("moneytree.landingLi1")}</li>
                    <li>{t("moneytree.landingLi2")}</li>
                    <li>{t("moneytree.landingLi3")}</li>
                </ul>
            </Col>
        </Row>
    );
};

export default MoneyTreeLandingPage;
