import { Row, Col, Stack, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { useNavigate } from "react-router";
import { useLogEventMutation } from "../../../api/api.ts";
import createLogEventBody from "../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";

const TruistBiometricsDeniedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [logEvent] = useLogEventMutation();
    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.biometricsDenied.clickBack.status));
        navigate(-1);
    };
    return (
        <>
            <LogoAndTimer />
            <Row className='vh-100 my-5 justify-content-between'>
                <Col xs={12} className='mb-3'>
                    <h1 className='mb-3 text-primary'>{t("biometricsDeniedPage.question")}</h1>
                    <Stack gap={3}>
                        <p>
                            <Trans i18nKey='truist.biometricsDenied1' />
                        </p>
                        <p>
                            <Trans i18nKey='truist.biometricsDenied2' />
                        </p>
                        <p>
                            <Trans i18nKey='truist.biometricsDenied3' />
                        </p>
                    </Stack>
                </Col>
                <Col xs={12} className='mb-5'>
                    <Button className='w-100' onClick={clickBack}>
                        {t("biometricsDeniedPage.back")}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default TruistBiometricsDeniedPage;
