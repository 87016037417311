import { ReactNode } from "react";
import ErrorBoundary from "./ErrorBoundary.tsx";
import ErrorPage from "../../pages/ErrorPages/ErrorPage.tsx";
import { useLogEventMutation } from "../../api/api.ts";

type ErrorBoundaryWrapperProps = {
    children: ReactNode;
};

const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWrapperProps) => {
    const [logEvent] = useLogEventMutation();

    return (
        <ErrorBoundary fallback={<ErrorPage />} logEvent={logEvent}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper;
