import React from "react";
import background from "../../assets/background.jpg";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { Col, Row } from "react-bootstrap";

type OwnProps = {
    children: React.ReactNode;
};

const Background: React.FC<OwnProps> = ({ children }) => {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);

    let routerVersion = urlParams.get("version");

    const companyBackground = getCompanyConfig("customBackground");
    const backgroundProps = getCompanyConfig("backgroundProps");

    const backgroundStyle = backgroundProps
        ? backgroundProps
        : companyBackground
        ? companyBackground.includes("./")
            ? {
                  backgroundImage: `url(${companyBackground})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
              }
            : {
                  backgroundColor: companyBackground,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
              }
        : {
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
          };

    return (
        <>
            <Row>
                <Col xs={12} style={backgroundStyle} className='z-n1 vh-100 position-fixed'></Col>
            </Row>
            <Row className={routerVersion === "westcor" ? "mx-3" : ""} style={{ margin: routerVersion === "certifid" ? "20px" : "" }}>
                <Col xs={12}>{children}</Col>
            </Row>
        </>
    );
};

export default Background;
