import { useEffect } from "react";
import DeviceFingerprint from "node_js_ipqs_device_tracker";
import { useIngestIpqsTrackerIdMutation, useLogEventMutation } from "../../api/api.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { StatusUpdateMessage, StatusUpdateSource } from "../../assets/statusUpdateEnums.ts";
import CustomerJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import createLogEventBody from "../../utils/createLogEventBody.js";

const IpqsTracker = () => {
    const [logEvent] = useLogEventMutation();
    const [ingestIpqsTrackerId] = useIngestIpqsTrackerIdMutation();
    const hasProcessedIpqs = sessionStorage.getItem("hasInitializedIPQS");
    const currRouterVersion = useSelector((state: RootState) => state.app.routerVersion);
    const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;

    const createUniqueID = () => {
        const newTrackerId = Date.now().toString(36) + Math.random().toString(36).substring(2);
        return newTrackerId;
    };

    const trackerId: any = createUniqueID();

    const handleIPQSResult = (result: any) => {
        const ipqsPayload = { ipqsTrackerId: trackerId, userAgent: navigator.userAgent };
        ingestIpqsTrackerId(ipqsPayload);
        if (apiVersion !== "1") {
            logEvent(createLogEventBody(CustomerJourneyCodes.landing.submitIpqsTrackerIdApiCall.status));
        }
        // dispatch(updateHasProcessedIpqs(true));
        sessionStorage.setItem("hasInitializedIPQS", "true");
    };

    const handleIPQSFailure = (result: any) => {
        logEvent(createLogEventBody(CustomerJourneyCodes.landing.ipqsInitFailure.status, result));
    };

    /** IPQS Ingestion */
    useEffect(() => {
        if (hasProcessedIpqs !== "true") {
            const ipqsKey = window.__RUNTIME_CONFIG__.REACT_APP_IPQS_KEY || "";
            DeviceFingerprint.initializeScriptAsync(ipqsKey)
                .then(() => {
                    DeviceFingerprint.AfterResult(handleIPQSResult as () => void);
                    DeviceFingerprint.AfterFailure(handleIPQSFailure as () => void);
                    DeviceFingerprint.Store("transactionID", trackerId);
                    DeviceFingerprint.Init();
                })
                .catch((err) => {
                    logEvent(createLogEventBody(CustomerJourneyCodes.landing.ipqsInitFailure.status, err));
                });
        }
    }, [currRouterVersion, hasProcessedIpqs]);

    return <></>;
};

export default IpqsTracker;
