import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "./languages/en-us/intellicheck.json";
import spanish from "./languages/es-mx/intellicheck.json";
import french from "./languages/fr-ca/intellicheck.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    "en-us": {
        translation: english,
    },
    "es-us": {
        translation: spanish,
    },
    "es-mx": {
        translation: spanish,
    },
    "fr-fr": {
        translation: french,
    },
    "fr-ca": {
        translation: french,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en-us",
        // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        nonExplicitSupportedLngs: true,
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
