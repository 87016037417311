import React from "react";
import Background from "../../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { useTranslation } from "react-i18next";

const AolCommunitiesTimeoutPage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <LogoAndTimer />
            <div className='vh-100 d-flex align-items-center mb-5'>
                <h3 className='text-center mb-5'>{t("timeoutPage")}</h3>
            </div>
        </Background>
    );
};

export default AolCommunitiesTimeoutPage;
