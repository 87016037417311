import { Row, Col, Stack, Button, Image } from "react-bootstrap";
import CertifIDFooter from "../../../assets/CertifID/CertifIDFooter.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import React from "react";

const CertifIDCameraPermissionsDeniedPage = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const { token, routerVersion, language } = user;
    const certifIDCameraPolicy =
        "https://certifidhelpcenter.zendesk.com/hc/en-us/articles/25385616854171-Granting-Permission-to-Use-Your-Device-s-Camera";

    return (
        <Row className='justify-content-center mb-5 vh-100' style={{ marginTop: "76px" }}>
            <Col xs={12}>
                <Stack gap={4}>
                    <h1>{t("certifid.cameraDeniedText1")}</h1>
                    <Stack gap={2}>
                        <p>{t("certifid.cameraDeniedText2")}</p>
                        <p>
                            {t("certifid.cameraDeniedText3")}{" "}
                            <a href={certifIDCameraPolicy} target='_blank' rel='noreferrer'>
                                {t("certifid.supportLink")}
                            </a>{" "}
                            {t("certifid.cameraDeniedText4")}
                        </p>
                    </Stack>
                    <Link reloadDocument to={`/?token=${token}&version=${routerVersion}&language=${language}&reload=true`}>
                        <Button variant='primary' className='my-3 w-100'>
                            {t("certifid.retryButton")}
                        </Button>
                    </Link>
                </Stack>
                <Row className='mt-5 mb-5'>
                    <Col className='d-flex justify-content-center'>
                        <Image src={CertifIDFooter} aria-label='Powered by CertifID' />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CertifIDCameraPermissionsDeniedPage;
