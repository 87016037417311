/**
 * Description: Defines a Redux slice for AuthId-related state management.
 * This slice handles AuthId specific state which is separate from the user.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

/**
 * Set initial AuthId state
 */
type AuthIdState = {
    shouldFetchBiometrics: boolean;
    selfieUrl: string | null;
};

const initialState: AuthIdState = {
    shouldFetchBiometrics: true,
    selfieUrl: null,
};

/**
 * Create the AuthId slice
 */
const authIdSlice = createSlice({
    name: "authid",
    initialState,
    reducers: {
        updateAuthIdSelfieUrl: (state, action: PayloadAction<string>) => {
            // console message index = 16
            state.selfieUrl = action.payload;
        },
        updateShouldFetchBiometrics: (state, action: PayloadAction<boolean>) => {
            // console message index = 17
            state.shouldFetchBiometrics = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    },
});

/**
 * Export AuthId slice actions
 */
export const { updateAuthIdSelfieUrl, updateShouldFetchBiometrics, reset: resetAuthIdState } = authIdSlice.actions;

/**
 * Export the reducer
 */
export default authIdSlice.reducer;
