import Background from "../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Logo from "../components/Logo/Logo.tsx";

const LoadingPage = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Background>
                <div className='d-flex justify-content-center'>
                    <Logo />
                </div>
                <div className='d-flex flex-column justify-content-start align-items-center mt-5'>
                    <h3 className='mb-4'>{t("loadingPage")}</h3>
                    <div className='spinner-border text-primary' role='status' />
                </div>
            </Background>
        </Container>
    );
};

export default LoadingPage;
