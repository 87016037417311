import React from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import AOLCommunitiesCheckMark from "../../../../assets/Yahoo/AOLCommunitiesCheckMark.tsx";

const AolCommunitiesCompletePage = () => {
    const { t } = useTranslation();
    return (
        <Row className='justify-content-center'>
            <Col xs={9}>
                <Stack gap={4} className='align-items-center text-center mt-5'>
                    <h1>{t("aolcommunities.complete")}</h1>
                    <AOLCommunitiesCheckMark />
                    <div>
                        <p>{t("aolcommunities.completeText1")}</p>
                        <p>
                            <Trans i18nKey='aolcommunities.completeText2' />
                        </p>
                    </div>
                </Stack>
            </Col>
        </Row>
    );
};

export default AolCommunitiesCompletePage;
