import ErrorBoundaryWrapper from "../../../../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import VideoNormalizerUI from "../../../../components/VideoNormalizer/IDCapture/VideoNormalizerUI.tsx";

const YahooEUPassportCapture = () => {
    return (
        <ErrorBoundaryWrapper>
            <VideoNormalizerUI currScanSide='Front' docType='Passport' />;
        </ErrorBoundaryWrapper>
    );
};
export default YahooEUPassportCapture;
