import { useTranslation, Trans } from "react-i18next";

const AmericanFirstFinanceLandingPage = () => {
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center align-items-center flex-column text-center position-relative'>
            <h1 className='mt-4 mt-md-5 mb-md-5'>
                <Trans i18nKey='americanfirstfinance.landingText1'>
                    A Better <span className='fw-bold'>YES!</span>
                </Trans>
            </h1>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text3")}</p>
        </div>
    );
};

export default AmericanFirstFinanceLandingPage;
