/**
 * The `TruistLandingPage` component serves as the landing or home page of the Truist application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the TruistLandingPage component in your application
 * import TruistLandingPage from './TruistLandingPage';
 *
 * // Render the TruistLandingPage component within a Route
 * <Route path='/' element={<TruistLandingPage />} />
 *
 * // When navigating to the root URL ('/'), the TruistLandingPage component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */

// import { useNavigate } from "react-router";
import { Stack, Button } from "react-bootstrap";
import SelfieImg from "../../../assets/selfie-image.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useTranslation } from "react-i18next";
import { useLogEventMutation } from "../../../api/api.ts";
import createLogEventBody from "../../../utils/createLogEventBody.js";

const TruistLandingPage = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();
    const { t } = useTranslation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));

        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <Stack gap={3} className='justify-content-center align-items-center'>
            <img src={SelfieImg} alt='Selfie' className='col-9 col-sm-9 col-md-7 mt-5' aria-label='Man verifying identity on phone' />
            <h2 className='text-center text-dark'>{t("truist.landingText1")}</h2>
            <Button className='col-11 col-md-8 p-2 p-md-3' variant='primary' onClick={clickStart}>
                {t("truist.landingButtonText")}
            </Button>
        </Stack>
    );
};

export default TruistLandingPage;
