import { Routes, Route, useLocation } from "react-router-dom";
import MainOutlet from "../MainOutlet.tsx";
import LandingPage from "../pages/LandingPage.tsx";
import CompletePage from "../pages/CompletePage.tsx";
import FrontPage from "../pages/FrontPage.tsx";
import SelfieCapture from "../components/SelfieCapture/AuthIdComponent.tsx";
import ErrorPage from "../pages/ErrorPages/ErrorPage.tsx";
import TimeoutPage from "../pages/ErrorPages/TimeoutPage.tsx";
import FourZeroFourPage from "../pages/ErrorPages/FourZeroFourPage.tsx";
import BiometricsDeniedPage from "../pages/ErrorPages/BiometricsDeniedPage.tsx";
import CameraPermissionsDenied from "../pages/ErrorPages/CameraPermissionsDeniedPage.tsx";
import { getCompanyConfig } from "../utils/getCompanyConfig.ts";
import React from "react";
import OldOutlet from "../OldOutlet.tsx";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import BarcodePage from "../pages/BarcodePage.tsx";
import BackPage from "../pages/BackPage.tsx";

const DefaultRouter = (): JSX.Element => {
    const currLocation = useLocation();
    const additionalRoutes = getCompanyConfig("additionalRoutes");

    return (
        <>
            <Routes location={currLocation} key={currLocation.pathname}>
                <Route element={<MainOutlet />}>
                    <Route
                        path='/'
                        element={
                            <ErrorBoundaryWrapper>
                                <LandingPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/selfie'
                        element={
                            <ErrorBoundaryWrapper>
                                <SelfieCapture />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/complete'
                        element={
                            <ErrorBoundaryWrapper>
                                <CompletePage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/error'
                        element={
                            <ErrorBoundaryWrapper>
                                <ErrorPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/token-expired'
                        element={
                            <ErrorBoundaryWrapper>
                                <TimeoutPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/biometrics-denied'
                        element={
                            <ErrorBoundaryWrapper>
                                <BiometricsDeniedPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/camera-denied'
                        element={
                            <ErrorBoundaryWrapper>
                                <CameraPermissionsDenied />
                            </ErrorBoundaryWrapper>
                        }
                    />

                    {additionalRoutes?.map((route: { route: string; element: React.ReactNode }) => (
                        <Route path={route.route} element={route.element} />
                    ))}
                    <Route
                        path='/*'
                        element={
                            <ErrorBoundaryWrapper>
                                <FourZeroFourPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                </Route>
                <Route element={<OldOutlet />}>
                    <Route
                        path='/front'
                        element={
                            <ErrorBoundaryWrapper>
                                <FrontPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/back'
                        element={
                            <ErrorBoundaryWrapper>
                                <BackPage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                    <Route
                        path='/barcode'
                        element={
                            <ErrorBoundaryWrapper>
                                <BarcodePage />
                            </ErrorBoundaryWrapper>
                        }
                    />
                </Route>
            </Routes>
        </>
    );
};

export default DefaultRouter;
