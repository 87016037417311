import React from "react";
import Background from "../../../components/Background/Background.tsx";
import Logo from "../../../components/Logo/Logo.tsx";
import { Container, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TruistTokenTimeout = () => {
    const { t } = useTranslation();

    return (
        <Container className='p-2'>
            <Background>
                <div className='d-flex justify-content-center p-2'>
                    <Logo />
                </div>
                <Stack gap={4} className='text-start mt-5 p-2'>
                    <h1 className='text-primary'>{t("truist.tokenTimeoutTitle")}</h1>
                    <p>{t("truist.tokenTimeoutText")}</p>
                </Stack>
            </Background>
        </Container>
    );
};

export default TruistTokenTimeout;
