import { Row, Col, Stack, Button, Image } from "react-bootstrap";
import CertifIDFooter from "../../../assets/CertifID/CertifIDFooter.svg";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { useTranslation } from "react-i18next";
import { useLogEventMutation } from "../../../api/api.ts";
import createLogEventBody from "../../../utils/createLogEventBody.js";

const CertifIDLandingPage = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();
    const { t } = useTranslation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    const clickCancel = () => {
        window.location.href = "https://portal.certifid.com/client/identity/warn/camera";
    };

    return (
        <Row className='justify-content-center mb-5 vh-100' style={{ marginTop: "76px" }}>
            <Col xs={12}>
                <Stack gap={4}>
                    <h1>{t("certifid.landingText1")}</h1>
                    <p>{t("certifid.landingText2")}</p>
                    <Stack gap={3}>
                        <Button variant='primary' onClick={clickStart}>
                            {t("certifid.continueButton")}
                        </Button>
                        <Button variant='outline-secondary' onClick={clickCancel}>
                            {t("certifid.doNotAllow")}
                        </Button>
                    </Stack>
                    <Row className='mt-5 mb-5'>
                        <Col className='d-flex justify-content-center'>
                            <Image src={CertifIDFooter} aria-label='Powered by CertifID' />
                        </Col>
                    </Row>
                </Stack>
            </Col>
        </Row>
    );
};

export default CertifIDLandingPage;
