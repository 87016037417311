import { Col, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CertifIDBiometricsPageContents = () => {
    const { t } = useTranslation();
    const biometricPolicy = "https://www.intellicheck.com/biometric-information-policy/";

    return (
        <Row style={{ marginTop: "76px" }}>
            <Col xs={12}>
                <Stack gap={4}>
                    <h1>{t("certifid.biometricsText1")}</h1>
                    <Stack gap={0}>
                        <p>
                            {t("certifid.biometricsText2")}
                            <span>
                                <a href={biometricPolicy} target='_blank' rel='noreferrer'>
                                    {t("selfieConsentModal.biometricPolicy")}
                                </a>
                            </span>
                            .
                        </p>
                        <p className='fw-bold'>{t("certifid.biometricsText3")}</p>
                    </Stack>
                </Stack>
            </Col>
        </Row>
    );
};

export default CertifIDBiometricsPageContents;
