import { useTranslation } from "react-i18next";
import FirstAmericanImage from "../../../assets/FirstAmericanTitle/FirstAmericanTitleLandingImage.png";
import { Stack } from "react-bootstrap";

const FirstAmericanTitleLanding = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={1} className='justify-content-center align-items-center text-center position-relative'>
            <h1 className='mt-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text1")}First American.</h1>
            <p className='mt-2 mb-2 mt-md-5 mb-md-3'>{t("genericLandingPageText.text2")}</p>
            <img className='col-8 col-md-12 mb-3' src={FirstAmericanImage} alt='First American Logo' aria-label='First American Logo' />
        </Stack>
    );
};

export default FirstAmericanTitleLanding;
