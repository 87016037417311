import { createSlice } from "@reduxjs/toolkit";

type DemoState = {
    barcodeScanResults: any;
    tokenUUID: any;
};

const initialState: DemoState = {
    barcodeScanResults: undefined,
    tokenUUID: undefined,
};

const demoSlice = createSlice({
    name: "demo",
    initialState,
    reducers: {
        setBarcodeScanResults: (state, action) => {
            return {
                ...state,
                barcodeScanResults: action.payload,
            };
        },
    },
});

export const { setBarcodeScanResults } = demoSlice.actions;

export default demoSlice.reducer;
