import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CertifIDImagePreview = () => {
    const { t } = useTranslation();

    return (
        <div style={{ paddingTop: "24px" }}>
            <h1 className='fw-bold mb-0' style={{ paddingBottom: "16px" }}>
                {t("certifid.imgPrevHeader")}
            </h1>
            <div>
                <p className='mb-0' style={{ paddingBottom: "8px" }}>
                    {t("certifid.imgPrevText")}
                </p>
                <ul>
                    <li style={{ paddingBottom: "8px" }}>{t("certifid.imgPrevli1")}</li>
                    <li style={{ paddingBottom: "8px" }}>{t("certifid.imgPrevli2")}</li>
                    <li>{t("certifid.imgPrevli3")}</li>
                </ul>
            </div>
            <Card border='primary'>
                <Card.Body style={{ padding: "8px" }}>
                    <Card.Text className='text-primary fw-bold'>{t("certifid.imgPrevCard")}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
};
export default CertifIDImagePreview;
