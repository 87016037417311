import { Button, Col, Row, Stack } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store.ts";
import { useLogEventMutation } from "../../../../api/api.ts";
import createLogEventBody from "../../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";

const AOLCommunitiesLandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    const clickIntellicheckPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    const clickYahooPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    return (
        <div className='vh-100 d-flex align-items-center'>
            <Row className='mb-5'>
                <Col xs={10} className='mb-5'>
                    <Stack gap={2}>
                        <h1>{t("aolcommunities.welcome")}</h1>
                        <p>
                            <Trans i18nKey='aolcommunities.bodyText1' />
                        </p>
                        <p>{t("aolcommunities.bodyText2")}</p>
                    </Stack>
                </Col>
                <Col xs={10} className='mx-auto mb-5'>
                    <Stack gap={3} className='mb-3'>
                        <Button variant='primary' className='w-100 rounded-pill p-3' onClick={clickStart}>
                            {t("aolcommunities.next")}
                        </Button>
                        <p className='text-center'>
                            {t("aolcommunities.privacyPolicy1")}
                            <a
                                href='https://www.intellicheck.com/privacy-policy'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickIntellicheckPrivacyPolicy}
                            >
                                {t("aolcommunities.privacyPolicy2")}
                            </a>
                            ,&nbsp;
                            <a href='https://www.intellicheck.com/biometric-information-policy'>{t("aolcommunities.privacyPolicy3")}</a>
                            {t("aolcommunities.privacyPolicy4")}
                            <a href={t("aolcommunities.yahooPrivacyPolicy")} target='_blank' rel='noreferrer' onClick={clickYahooPrivacyPolicy}>
                                {t("aolcommunities.privacyPolicy5")}
                            </a>
                            .
                        </p>
                    </Stack>
                </Col>
            </Row>
        </div>
    );
};

export default AOLCommunitiesLandingPage;
