import Background from "../../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Button, Stack } from "react-bootstrap";
import createLogEventBody from "../../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useLogEventMutation } from "../../../../api/api.ts";

const AOLCommunitiesFourZeroFourPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    const [logEvent] = useLogEventMutation();
    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors[404].clickBack.status));
        navigate(-1);
    };

    return (
        <Background>
            <LogoAndTimer />
            <div className='vh-100 d-flex mb-5'>
                <Stack gap={5} className='d-flex justify-content-center align-items-center mb-5'>
                    <h3 className='text-center'>{t("fourZeroFourPage.tryAgain")}</h3>
                    <Button onClick={clickBack}>{t("fourZeroFourPage.back")}</Button>
                    <p>{token}</p>
                </Stack>
            </div>
        </Background>
    );
};

export default AOLCommunitiesFourZeroFourPage;
