import { Button, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import createLogEventBody from "../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { useLogEventMutation } from "../../../api/api.ts";

const AcimaLandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        // ("User clicked start data collection");
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    return (
        <Stack gap={3}>
            <h1 className='text-center'>{t("acima.header")}</h1>
            <>
                <p>{t("acima.body1")}</p>
                <ul>
                    <li>{t("acima.li1")}</li>
                    <li>{t("acima.li2")}</li>
                    <li>{t("acima.li3")}</li>
                </ul>
                <p>{t("acima.body2")}</p>
            </>
            <Button variant='primary' onClick={clickStart} className='p-2'>
                {t("landingPage.start")}
            </Button>
            <div className='text-center'>
                <h1 className='fw-bold'>
                    {captureReqs.length} - {t("landingPage.step")}
                </h1>
                <h2 className='fw-bold'>{t("landingPage.identityValidation")}</h2>
            </div>
            <p>
                {t("landingPage.privacyPolicyMessage")}{" "}
                <a
                    onClick={() => logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status))}
                    href={t("landingPage.privacyPolicyLink")}
                    target='_blank'
                >
                    {t("landingPage.privacyPolicy")}
                </a>
                .
            </p>
        </Stack>
    );
};
export default AcimaLandingPage;
