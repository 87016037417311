import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Background from "../../../components/Background/Background.tsx";

const MoneyTreeCompletePage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <Stack gap={3} className='align-items-center mt-5'>
                <h1 className='text-success fw-bold'>{t("completePage.submitted")}</h1>
                <p>{t("completePage.complete")}</p>
                <p>{t("completePage.close")}</p>
            </Stack>
        </Background>
    );
};

export default MoneyTreeCompletePage;
