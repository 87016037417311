import React, { useEffect } from "react";
import BarcodeImageAndDecode from "../components/VideoCapture/TwoInOne/VideoCapture.tsx";
import { getCompanyConfig } from "../utils/getCompanyConfig.ts";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";
import { APIVersion } from "../assets/apiVersionEnums.ts";
import { useLogEventMutation } from "../api/api.ts";
import createLogEventBody from "../utils/createLogEventBody.js";

const BarcodePage = () => {
    const [logEvent] = useLogEventMutation();
    const barcodeCaptureComponent = getCompanyConfig("barcodeCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.navToPage.status));
    }, []);

    return <ErrorBoundaryWrapper>{barcodeCaptureComponent ? barcodeCaptureComponent : <BarcodeImageAndDecode />}</ErrorBoundaryWrapper>;
};

export default BarcodePage;
