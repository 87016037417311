import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";
import { Col, Row } from "react-bootstrap";

const WestcorLandingPage = () => {
    const { t } = useTranslation();
    const captureAssets = useSelector((state: RootState) => state.user.captureAssets);

    return (
        <Row className='text-center mt-2 mt-md-4'>
            {captureAssets && (
                <>
                    <Col xs={12} className='mb-3 mb-md-4'>
                        {t("westcor.presentedBehalf")}
                        {captureAssets.companyName}
                    </Col>
                    <Col xs={12}>
                        <img className='w-25 d-block mx-auto' src={`data:image/png;base64,${captureAssets.logo}`} />
                    </Col>
                </>
            )}
            <Col>
                <h1 className='mt-2 mb-3 my-md-4 fw-bold'>{t("westcor.landingText1")}</h1>
                <p className='mt-2 mb-3 my-md-4'>{t("genericLandingPageText.text2")}</p>
                <p className='mt-2 mb-3 mt-md-4'>{t("genericLandingPageText.text3")}</p>
            </Col>
        </Row>
    );
};
export default WestcorLandingPage;
