import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.ts";

const AcimaCameraDeniedPage = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const { token, routerVersion, language } = user;

    return (
        <>
            <LogoAndTimer />
            <Row className='vh-100 justify-content-between'>
                <Col xs={12} className='mb-5'>
                    <Stack gap={3}>
                        <h1 className='mb-3 text-center'>{t("cameraPermissionsDeniedPage.question")}</h1>
                        <div>
                            <p>{t("cameraPermissionsDeniedPage.description")}</p>
                            <ul>
                                <li>
                                    <Trans i18nKey='acima.camera1' />
                                </li>
                                <li>
                                    <Trans i18nKey='acima.camera2' />
                                </li>
                            </ul>
                        </div>
                    </Stack>
                </Col>
                <Col xs={12} className='mt-4 mb-5'>
                    <Link reloadDocument to={`/?token=${token}&version=${routerVersion}&language=${language}&reload=true`}>
                        <Button className='w-100'>{t("cameraPermissionsDeniedPage.back")}</Button>
                    </Link>
                </Col>
            </Row>
        </>
    );
};
export default AcimaCameraDeniedPage;
