/**
 * The `CompletePage` component represents a page indicating the completion of a task or process.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling and navigation
 * is handled using the `useNavigate` hook from React Router.
 *
 * @component
 * @example
 * // Import the CompletePage component in your application
 * import CompletePage from './CompletePage';
 *
 * // Render the CompletePage component within a Route
 * <Route path='/complete' element={<CompletePage />} />
 *
 * // When navigating to '/complete', the CompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */
import { useEffect, useRef } from "react";
import Background from "../components/Background/Background.tsx";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "../redux/slices/user.slice.ts";
import { getCompanyConfig } from "../utils/getCompanyConfig.ts";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";
import { RootState } from "../redux/store";
import LogoAndTimer from "../components/LogoAndTimer/LogoAndTimer.tsx";
import { Container, Spinner } from "react-bootstrap";
import { useLogEventMutation } from "../api/api.ts";
import createLogEventBody from "../utils/createLogEventBody.js";

const CompletePage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [logEvent] = useLogEventMutation();
    const redirectUrl = useSelector((state: RootState) => state.user.redirectUrl);
    const completed = useSelector((state: RootState) => state.user.completed);

    const completeComponent = getCompanyConfig("completeComponent");
    const completeRedirectTimeout = getCompanyConfig("completeRedirectTimeout");
    const redirectTimeOut = completeRedirectTimeout !== null || completeRedirectTimeout !== undefined ? completeRedirectTimeout : 1500;

    useEffect(() => {
        if (!completed) {
            dispatch(setUserObject({ completed: true }));
            logEvent(createLogEventBody(CustJourneyCodes.complete.navToPage.status));
        }
    }, []);

    if (redirectUrl) {
        setTimeout(() => {
            window.location.href = redirectUrl;
        }, redirectTimeOut);
    }

    return (
        <Container>
            <Background>
                <LogoAndTimer />
                {completeComponent ? (
                    completeComponent
                ) : (
                    <div className='d-flex flex-column justify-content-center align-items-center text-center h-100'>
                        {redirectUrl ? (
                            <>
                                <Spinner />
                                <p className='text-center'>{t("completePage.redirect")}</p>
                            </>
                        ) : (
                            <>
                                <div>
                                    <h1 className='text-success fw-bold'>{t("completePage.submitted")}</h1>
                                    <h3 className='fw-bold'>{t("completePage.complete")}</h3>
                                </div>
                                <p className='text-center'>{t("completePage.close")}</p>
                            </>
                        )}
                    </div>
                )}
            </Background>
        </Container>
    );
};

export default CompletePage;
