import { useTranslation } from "react-i18next";

const ViveFinancialLandingPage = () => {
    const { t } = useTranslation();
    return (
        <div className='my-4'>
            <h3 className='text-center'>{t("vive-financial.header")}</h3>
            <p>{t("vive-financial.p")}</p>
            <ul>
                <li>{t("vive-financial.li1")}</li>
                <li>{t("vive-financial.li2")}</li>
                <li>{t("vive-financial.li3")}</li>
                <li>{t("vive-financial.li4")}</li>
            </ul>
        </div>
    );
};
export default ViveFinancialLandingPage;
