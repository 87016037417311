/**
 * The `MainOutlet` component serves as the main content container for nested route rendering.
 *
 * It is typically used as a layout component that acts as a placeholder for the content to be displayed
 * within nested routes. It utilizes the `Outlet` component from React Router to render the content
 * of the child routes based on the current route's path.
 *
 * @component
 * @example
 * // Import the MainOutlet component in the application
 * import MainOutlet from './MainOutle.tsx';
 *
 * // Include the MainOutlet component within the route structure
 * <Route element={<MainOutlet />}>
 *  {child routes}
 * </Route>
 *
 * // When navigating to '/child-route-1', ChildComponent1 will be rendered within MainOutlet.
 * // When navigating to '/child-route-2', ChildComponent2 will be rendered within MainOutlet.
 *
 * @returns {JSX.Element} The JSX element representing the main content container.
 */
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const MainOutlet = () => {
    return (
        <Container fluid>
            <Outlet />
        </Container>
    );
};

export default MainOutlet;
