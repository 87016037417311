import { useEffect } from "react";
import { getCompanyConfig } from "../utils/getCompanyConfig.ts";
import ErrorBoundaryWrapper from "../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";
import CustJourneyCodes from "../assets/CustomerJourneyCodes.json";
import VideoNormalizerUI from "../components/VideoNormalizer/IDCapture/VideoNormalizerUI.tsx";
import { useLogEventMutation } from "../api/api.ts";
import createLogEventBody from "../utils/createLogEventBody.js";

const FrontPage = () => {
    const [logEvent] = useLogEventMutation();
    const frontCaptureComponent = getCompanyConfig("frontCaptureComponent");

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.captureFront.navToPage.status));
    }, []);

    return (
        <ErrorBoundaryWrapper>
            {frontCaptureComponent ? frontCaptureComponent : <VideoNormalizerUI currScanSide='Front' docType='NA_ID' />}
        </ErrorBoundaryWrapper>
    );
};
export default FrontPage;
