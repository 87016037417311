import { Routes, Route, useLocation } from "react-router-dom";
import MainOutlet from "../MainOutlet.tsx";
import FrontPage from "../pages/FrontPage.tsx";
import SelfieCapture from "../components/SelfieCapture/AuthIdComponent.tsx";
import ErrorPage from "../pages/ErrorPages/ErrorPage.tsx";
import TimeoutPage from "../pages/ErrorPages/TimeoutPage.tsx";
import FourZeroFourPage from "../pages/ErrorPages/FourZeroFourPage.tsx";
import BiometricsDeniedPage from "../pages/ErrorPages/BiometricsDeniedPage.tsx";
import CameraPermissionsDenied from "../pages/ErrorPages/CameraPermissionsDeniedPage.tsx";
import BarcodePage from "../pages/BarcodePage.tsx";
import AcmeLandingPage from "../pages/custom/Acme/AcmeLandingPage.tsx";
import AcmeCompletePage from "../pages/custom/Acme/AcmeCompletePage.tsx";
import AcmeResultsPage from "../pages/custom/Acme/AcmeResultsPage.tsx";
import OldOutlet from "../OldOutlet.tsx";
import React from "react";

const AcmeRouter = (): JSX.Element => {
    const currLocation = useLocation();
    return (
        <>
            <Routes location={currLocation} key={currLocation.pathname}>
                <Route element={<MainOutlet />}>
                    <Route path='/' element={<AcmeLandingPage />} />

                    <Route path='/selfie' element={<SelfieCapture />} />

                    <Route path='/results' element={<AcmeResultsPage />} />

                    <Route path='/complete' element={<AcmeCompletePage />} />
                    <Route path='/error' element={<ErrorPage />} />
                    <Route path='/token-expired' element={<TimeoutPage />} />
                    <Route path='/biometrics-denied' element={<BiometricsDeniedPage />} />
                    <Route path='/camera-denied' element={<CameraPermissionsDenied />} />
                    <Route path='/*' element={<FourZeroFourPage />} />
                </Route>
                <Route element={<OldOutlet />}>
                    <Route path='/barcode' element={<BarcodePage />} />
                    <Route path='/front' element={<FrontPage />} />
                </Route>
            </Routes>
        </>
    );
};

export default AcmeRouter;
