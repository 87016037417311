import React, { useEffect, useRef, useState } from "react";
import { EnumCapturedResultItemType, EnumImagePixelFormat, OriginalImageResultItem } from "dynamsoft-core";
import { EnumBarcodeFormat } from "dynamsoft-barcode-reader";
import { CameraEnhancer, CameraView } from "dynamsoft-camera-enhancer";
import { CapturedResultReceiver, CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter } from "dynamsoft-utility";
import "./cvr.ts"; // import side effects. The license, engineResourcePath, so on.
import "./VideoCapture.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getNextPage } from "../../../redux/slices/user.slice.ts";
import { useIngestBarcodeDataMutation, useLogEventMutation } from "../../../api/api.ts";
import barcodeOverlay from "../../../assets/barcode.png";
import { Base64 } from "js-base64";
import ExpirationTimer from "../../ExpirationTimer/ExpirationTimer.tsx";
import { getCompanyConfig } from "../../../utils/getCompanyConfig.ts";
import { ImageManager } from "dynamsoft-utility";
import { headingStyles } from "../../CaptureMessages/CaptureMessages.tsx";
import { Badge, Col, Row } from "react-bootstrap";
import ErrorBoundaryWrapper from "../../ErrorBoundary/ErrorBoundaryWrapper.tsx";
import { setBarcodeScanResults } from "../../../redux/slices/demo.slice.ts";
import { APIVersion } from "../../../assets/apiVersionEnums.ts";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import createLogEventBody from "../../../utils/createLogEventBody.js";
import CaptureMessages from "../../CaptureMessages/CaptureMessages";

type VideoCaptureProps = {
    nextPath?: string;
};

interface OverlayDimensions {
    scanBoxWidth: string;
    scanBoxHeight: string;
    leftPosition: string;
    topPosition: string;
}

let isCameraPermissionAccepted = false;

const BarcodeImageAndDecode: React.FC<VideoCaptureProps> = ({ nextPath }) => {
    const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;

    const uiContainer = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements, token, routerVersion, language } = user;
    const url = nextPath ? `/${nextPath}?${urlParams}` : `/${getNextPage(captureRequirements, "barcode")}?${urlParams}`;
    const navigate = useNavigate();

    const [overlayDimensions, setOverlayDimensions] = useState<OverlayDimensions | null>(null);
    const [sendData, sendDataResponse] = useIngestBarcodeDataMutation();
    const [additionalMessage, setAdditionalMessage] = useState<boolean>(false);
    const [logEvent] = useLogEventMutation();
    const hasCapturedBarcode = useRef(false);
    const hideCountdownTimer = getCompanyConfig("hideCountdownTimer");

    const pInit = useRef(
        null as Promise<{
            cameraView: CameraView;
            cameraEnhancer: CameraEnhancer;
            router: CaptureVisionRouter;
        }> | null,
    );
    const pDestroy = useRef(null as Promise<void> | null);

    // Listens for response from sending the barcode and navigates to the next page when successfully sent to server
    useEffect(() => {
        if (sendDataResponse.isSuccess) {
            dispatch(setBarcodeScanResults(sendDataResponse.data.payload));
            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.imageCaptured.status));
            navigate(url);
        }
        if (sendDataResponse.isError) {
        }
    }, [sendDataResponse.isSuccess, sendDataResponse.error]);

    useEffect(() => {
        setTimeout(() => {
            setAdditionalMessage(true);
        }, 7500);
    }, []);

    const hideDropDowns = () => {
        const cameraSelElement = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-camera") || null;
        const resolutionSelElement =
            document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-sel-resolution") || null;
        if (cameraSelElement) {
            (cameraSelElement as any).style.display = "none";
        }
        if (resolutionSelElement) {
            (resolutionSelElement as any).style.display = "none";
        }
    };

    const processFile = (file: any, barcodeData: any) => {
        let objectURL = URL.createObjectURL(file);

        // Create a new Image element
        let src_image = new Image();

        // Set up onload event listener for the image
        src_image.onload = () => {
            let canvas = document.createElement("canvas");
            canvas.height = src_image.height;
            canvas.width = src_image.width;
            let ctx = canvas.getContext("2d");
            ctx?.drawImage(src_image, 0, 0);
            let imageData = canvas.toDataURL("image/png");
            // alert(`imageData: ${imageData}`);

            // TODO Why is the send in here?
            const base64Barcode = Base64.encode(barcodeData);
            // alert(`Sending ImageData: ${b64ImgData.current}`);
            const transactionBody = {
                barcodeData: base64Barcode,
                backImage: imageData.replace("data:image/png;base64,", ""),
            };
            sendData(transactionBody);
        };

        // Set the src attribute of the image to the object URL
        src_image.src = objectURL;
    };

    let cameraEnhancer: CameraEnhancer, cameraView: CameraView, router: CaptureVisionRouter;

    const init = async () => {
        try {
            cameraView = await CameraView.createInstance("./dce.ui.html");
            cameraView && cameraView.setScanLaserVisible(false);
            let dbrDrawingLayer = cameraView.getDrawingLayer(2);
            dbrDrawingLayer.setVisible(false);
            cameraEnhancer = await CameraEnhancer.createInstance(cameraView);

            cameraView.setVideoFit("cover");
            await cameraEnhancer?.setResolution({
                width: 1920,
                height: 1080,
            });

            // adding code to hide highligh toverlay

            uiContainer.current!.innerText = "";
            uiContainer.current!.append(cameraView.getUIElement());

            const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
            if (message) {
                (message as any).style.display = "none";
            }

            router = await CaptureVisionRouter.createInstance();
            router.setInput(cameraEnhancer);

            // Create a `CameraEnhancer` instance for camera control and a `CameraView` instance for UI control.

            let settings = await router.getSimplifiedSettings("ReadDenseBarcodes");
            settings.capturedResultItemTypes |= EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE;
            settings.barcodeSettings.barcodeFormatIds = EnumBarcodeFormat.BF_PDF417;
            settings.barcodeSettings.expectedBarcodesCount = 1;
            settings.barcodeSettings.grayscaleTransformationModes = [2, 0, 0, 0, 0, 0, 0, 0];
            settings.barcodeSettings.localizationModes = [16, 8, 2, 0, 0, 0, 0, 0];
            settings.barcodeSettings.minResultConfidence = 0;
            await router.updateSettings("ReadDenseBarcodes", settings);
            await router.startCapturing("ReadDenseBarcodes");

            const resultReceiver = new CapturedResultReceiver();

            resultReceiver.onCapturedResultReceived = (result) => {
                let barcodes = result.items.filter((item) => item.type === EnumCapturedResultItemType.CRIT_BARCODE);
                if (barcodes?.length > 0 && !hasCapturedBarcode.current) {
                    hasCapturedBarcode.current = true;
                    let image = result.items.filter(
                        (item) => item.type === EnumCapturedResultItemType.CRIT_ORIGINAL_IMAGE,
                    )[0] as OriginalImageResultItem;
                    const resultBarcode = (barcodes[0] as any).text;

                    const imgManager = new ImageManager();
                    imgManager.saveToFile(image.imageData, "withBarcode.png").then((file: any) => {
                        processFile(file, resultBarcode);
                    });
                }
            };
            router.addResultReceiver(resultReceiver);

            const filter = new MultiFrameResultCrossFilter();
            filter.enableResultCrossVerification(EnumCapturedResultItemType.CRIT_BARCODE, true);
            filter.enableResultDeduplication(EnumCapturedResultItemType.CRIT_BARCODE, true);
            filter.setDuplicateForgetTime(EnumCapturedResultItemType.CRIT_BARCODE, 3000);
            await router.addResultFilter(filter);

            const cameras = await cameraEnhancer?.getAllCameras();
            if (cameras && cameras?.length) {
                const backCam = cameras.find((cam) => cam?.label?.toLowerCase().includes("back camera(hd)"));
                if (backCam) {
                    // if we have an ultrawide, we are on a iOS device, so set it.
                    await cameraEnhancer?.selectCamera(backCam);
                }
                // if we don't, let dynamsoft do the work of choosing the camera
            }

            await cameraEnhancer.open();
            cameraEnhancer.setPixelFormat(EnumImagePixelFormat.IPF_ABGR_8888);
            await router.startCapturing("ReadSingleBarcode");
            isCameraPermissionAccepted = true;
            if (!window.__RUNTIME_CONFIG__.REACT_APP_DEBUG_DYNAMSOFT) {
                setTimeout(() => {
                    hideDropDowns();
                    cameraView.setScanLaserVisible(false);
                    const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
                    if (message) {
                        (message as any).style.display = "none";
                    }
                }, 150);
            }
            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.captureComponentInit.status));

            return {
                cameraView,
                cameraEnhancer,
                router,
            };
        } catch (ex: any) {
            let errMsg: string;

            if (ex.message.includes("denied permission")) {
                navigate(`/camera-denied?token=${token}&version=${routerVersion}&language=${language}`);
            }
            if (ex.message.includes("network connection error")) {
                errMsg =
                    "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
            } else if (ex.message.includes("Permission denied")) {
                errMsg = "Camera permissions were denied.";

                navigate(`/camera-denied?token=${token}&version=${routerVersion}&language=${language}`);
            } else {
                errMsg = ex.message || ex;
            }

            logEvent(createLogEventBody(CustJourneyCodes.captureBarcode.captureComponentInitError.status, ex));

            throw ex;
        }
    };

    const calculateScanAreaDimensions = (): OverlayDimensions => {
        return {
            scanBoxWidth: "88vw", // 88% of viewport width
            scanBoxHeight: "32vh", // 32% of viewport height
            leftPosition: "6vw", // 6% from the left of viewport
            topPosition: "15vh", // 15% from the top of viewport
        };
    };

    useEffect(() => {
        setOverlayDimensions(calculateScanAreaDimensions());
    }, []);

    useEffect(() => {
        if (cameraView) cameraView.setScanLaserVisible(false);
        const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
        if (message) {
            (message as any).style.display = "none";
        }
    }, []);

    useEffect(() => {
        const checkContainer = () => {
            const dimensions = calculateScanAreaDimensions();
            if (dimensions) {
                setOverlayDimensions(dimensions);
            } else {
                setTimeout(checkContainer, 100);
            }
        };

        checkContainer();
    }, []);

    const destroy = async (): Promise<void> => {
        if (pInit.current) {
            const { cameraView, cameraEnhancer, router } = await pInit.current;
            router.dispose();
            cameraEnhancer.dispose();
            cameraView.dispose();
        }
    };

    useEffect(() => {
        (async () => {
            // In 'development', React runs setup and cleanup one extra time before the actual setup in Strict Mode.
            if (pDestroy.current) {
                await pDestroy.current;
                pInit.current = init();
                cameraView.setScanLaserVisible(false);
                const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
                if (message) {
                    (message as any).style.display = "none";
                }
            } else {
                pInit.current = init();
                cameraView.setScanLaserVisible(false);
                const message = document.getElementById("div-ui-container")?.children[0]?.shadowRoot?.querySelector(".dce-msg-poweredby") || null;
                if (message) {
                    (message as any).style.display = "none";
                }
            }
        })();

        return () => {
            (async () => {
                await (pDestroy.current = destroy());
            })();
        };
    }, []);

    return (
        <ErrorBoundaryWrapper>
            <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", zIndex: 1003 }}>
                <Row className='justify-content-end me-1' style={{ zIndex: 1003 }}>
                    {!hideCountdownTimer && (
                        <Col xs={"auto"}>
                            <ExpirationTimer shouldRender={true} />
                        </Col>
                    )}
                </Row>
                {isCameraPermissionAccepted && (
                    <>
                        <div
                            className='text-center position-absolute'
                            style={{
                                width: "100vw",
                                zIndex: 1003,
                                top: "7vh",
                            }}
                        >
                            <Badge className='p-2 fs-5' bg={headingStyles.backgroundColor} style={headingStyles}>
                                {t("captureMessages.videoDecode")}
                            </Badge>
                        </div>
                        <div
                            className='text-center position-absolute'
                            style={{
                                width: "100vw",
                                zIndex: 1003,
                                top: "65vh",
                            }}
                        >
                            <Badge
                                bg={headingStyles.backgroundColor}
                                style={{
                                    ...headingStyles,
                                    fontSize: "16px",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                    overflowWrap: "break-word",
                                    margin: "0 5%",
                                    zIndex: 1003,
                                }}
                                aria-live='assertive'
                            >
                                {t("captureMessages.flipIDToBack")}
                            </Badge>
                            {additionalMessage ? (
                                <Row style={{ ...headingStyles, zIndex: 1003 }} aria-live='polite' className='w-75 py-2 px-3 mt-3 rounded-2 mx-auto'>
                                    {t("captureMessages.frontMessageBarcode")}
                                </Row>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
            {isCameraPermissionAccepted && overlayDimensions && (
                <>
                    <div
                        className='position-fixed top-0 start-0'
                        style={{
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            clipPath: `polygon(
                                    0 0, 0 100%, ${overlayDimensions.leftPosition} 100%, ${overlayDimensions.leftPosition} ${overlayDimensions.topPosition},
                                    calc(${overlayDimensions.leftPosition} + ${overlayDimensions.scanBoxWidth}) ${overlayDimensions.topPosition},
                                    calc(${overlayDimensions.leftPosition} + ${overlayDimensions.scanBoxWidth}) calc(${overlayDimensions.topPosition} + ${overlayDimensions.scanBoxHeight}),
                                    ${overlayDimensions.leftPosition} calc(${overlayDimensions.topPosition} + ${overlayDimensions.scanBoxHeight}),
                                    ${overlayDimensions.leftPosition} 100%, 100% 100%, 100% 0
                                )`,
                            zIndex: 1000,
                        }}
                    ></div>

                    {/* White rectangle overlay with guidance image */}
                    <div
                        className='position-fixed'
                        aria-live='polite'
                        aria-label={`Place ID within this frame`}
                        style={{
                            height: overlayDimensions.scanBoxHeight,
                            width: overlayDimensions.scanBoxWidth,
                            border: "2px solid white",
                            borderRadius: "15px",
                            left: overlayDimensions.leftPosition,
                            top: overlayDimensions.topPosition,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: slight fill for the frame
                            zIndex: 1001,
                            boxSizing: "border-box",
                        }}
                    >
                        {/* <img
                                src={barcodeOverlay}
                                alt='Place Barcode within this frame'
                                style={{
                                    opacity: "0.2",
                                    height: "90%", // Adjust to fit within the rectangle
                                    width: "90%", // Adjust to fit within the rectangle
                                    borderRadius: "10px",
                                }}
                            /> */}
                    </div>
                </>
            )}
            {
                <div
                    id='div-ui-container'
                    className='vh-100 vw-100'
                    aria-hidden='true'
                    style={{
                        height: "92vh",
                        width: "100vw",
                    }}
                    ref={uiContainer}
                />
            }
        </ErrorBoundaryWrapper>
    );
};

export default BarcodeImageAndDecode;
