import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TruistBiometricsPageContents = () => {
    const { t } = useTranslation();
    const biometricPolicy = "https://www.intellicheck.com/biometric-information-policy/";

    const bioMetricsText = t("selfieConsentModal.biometricPolicy");

    return (
        <Stack gap={1} className='text-left'>
            <p className='text-start'>
                {t("truist.biometricPolicy")}
                {/* {t("truist.biometricPolicy2")} */}
            </p>
            <p className='mt-3 text-start'>{t("truist.biometricPolicy3")} </p>
            <Stack gap={3} direction='horizontal'>
                <p className='mt-3 text-start'>
                    <a href={biometricPolicy} target='_blank' rel='noreferrer' className='text-primary'>
                        Intellicheck's {bioMetricsText.toLowerCase()}
                    </a>
                </p>
                <i className='bold bi bi-box-arrow-up-right text-primary'></i>
            </Stack>
        </Stack>
    );
};

export default TruistBiometricsPageContents;
