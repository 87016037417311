import { useTranslation, Trans } from "react-i18next";
import { Stack } from "react-bootstrap";

const FirstAmericanTitleComplete = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={5} className='h-100 text-center d-flex flex-column justify-content-center align-items-center'>
            <div>
                <h1 className='text-success fw-bold'>{t("firstamericantitle.completeText1")}</h1>
                <h3 className='fw-bold'>{t("firstamericantitle.completeText2")}</h3>
            </div>
            <p className='col-10 lh-sm text-dark'>
                <Trans i18nKey='firstamericantitle.completeText3'>
                    In a few moments, this <span className='fw-bold'>page will automatically refresh</span>, and you can continue with next steps.
                </Trans>
            </p>
            <p>
                <b>{t("firstamericantitle.completeText4")}</b>
            </p>
        </Stack>
    );
};

export default FirstAmericanTitleComplete;
