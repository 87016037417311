/**
 * The `YahooUSCompletePage` component represents a page indicating the completion of a task or process for YahooUS.
 *
 * It typically displays a success message or relevant content and may include a button or link to navigate
 * back to the main or home page. This component uses React Bootstrap components for styling.
 *
 * @component
 * @example
 * // Import the YahooUSCompletePage component in your application
 * import YahooUSCompletePage from "../YahooUSCompletePage';
 *
 * // Render the YahooUSCompletePage component within a Route
 * <Route path='/complete' element={<YahooUSCompletePage />} />
 *
 * // When navigating to '/complete', the YahooUSCompletePage component will be displayed.
 *
 * @returns {JSX.Element} The JSX element representing the completion page.
 */
import { Stack } from "react-bootstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogEventMutation } from "../../../../api/api.ts";
import YahooUSCheckMark from "../../../../assets/Yahoo/YahooUSCheckMark.tsx";
import createLogEventBody from "../../../../utils/createLogEventBody.js";
import CustomerJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";

const YahooUSCompletePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isSubmitFrontSuccess = location?.state?.isSubmitFrontSuccess;
    const [logEvent] = useLogEventMutation();

    useEffect(() => {
        if (isSubmitFrontSuccess) {
            try {
                logEvent(createLogEventBody(CustomerJourneyCodes.complete.navToPage.status));
            } catch (error) {
                logEvent(createLogEventBody(CustomerJourneyCodes.errors[404].navToPage.status, "Something went wrong submitting the front image."));
            }
        }
    }, [isSubmitFrontSuccess]);

    return (
        <>
            <Stack gap={2} className='justify-content-center align-items-center text-center'>
                <h1 className='fw-bolder fs-3 mb-5'>{t("yahooUS.complete")}</h1>
                <YahooUSCheckMark />
                <p className='fw-bold col-12 col-md-8 fw-bold mt-5'>{t("yahooUS.completeMessage1")}</p>
                <p className='fw-bold col-12 col-md-8 fw-bold'>{t("yahooUS.completeMessage2")}</p>
            </Stack>
        </>
    );
};

export default YahooUSCompletePage;
