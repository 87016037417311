import React from "react";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FlexitiLanding = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={1} className='text-center'>
            <h1 className='mt-4 mt-md-5 mb-md-5'>{t("flexiti.landingText1")}</h1>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text3")}</p>
        </Stack>
    );
};

export default FlexitiLanding;
