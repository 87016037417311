/**
 * The `YahooUSLandingPage` component serves as the landing or home page of the YahooUS application.
 *
 * It typically displays a welcome message or introductory content and includes a button or link
 * to navigate to another page, often the starting point of the application. Navigation is handled
 * using the `useNavigate` hook from React Router, and styling is accomplished using React Bootstrap components.
 *
 * @component
 * @example
 * // Import the YahooUSLandingPage component in your application
 * import YahooUSLandingPage from "../YahooUSLandingPage';
 *
 * // Render the YahooUSLandingPage component within a Route
 * <Route path='/' element={<YahooUSLandingPage />} />
 *
 * // When navigating to the root URL ('/'), the YahooEULandingPage component will be displayed as the home page.
 *
 * @returns {JSX.Element} The JSX element representing the landing page.
 */
import { Button, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redux/store.ts";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";
import { useLogEventMutation } from "../../../../api/api.ts";
import createLogEventBody from "../../../../utils/createLogEventBody.js";

const YahooUSLandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs, token, routerVersion, language } = user;
    const [logEvent] = useLogEventMutation();

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?token=${token}&version=${routerVersion}&language=${language}`);
    };

    const clickIntellicheckPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    const clickYahooEUPrivacyPolicy = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickPolicyLink.status));
    };

    return (
        <div className='h-100 d-flex justify-content-center align-items-center'>
            <Stack gap={2} className='my-auto col-10 col-md-7 text-center'>
                <h1 className='fw-bolder fs-3'>{t("yahooUS.welcome")}</h1>
                <p>{t("yahooUS.welcome1")}</p>
                <p>{t("yahooUS.welcome2")}</p>
                <p>{t("yahooUS.welcome3")}</p>

                <div className='mt-5 col-10 col-md-7 mx-auto'>
                    <Button className='col-11 col-md-9 mb-3' variant='primary' onClick={clickStart}>
                        {t("yahooUS.nextButton")}
                    </Button>
                    <p>
                        <small>
                            {t("yahooUS.acknowlege")}{" "}
                            <a
                                href='https://www.intellicheck.com/privacy-policy/'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickIntellicheckPrivacyPolicy}
                            >
                                {t("yahooUS.privacyPolicyIntellicheck")}
                            </a>{" "}
                            {t("yahooUS.andIn")}{" "}
                            <a
                                href='https://legal.yahoo.com/ie/en/yahoo/privacy/index.html'
                                target='_blank'
                                rel='noreferrer'
                                onClick={clickYahooEUPrivacyPolicy}
                            >
                                {t("yahooUS.privacyPolicyYahoo")}
                            </a>
                            .
                        </small>
                    </p>
                </div>
            </Stack>
        </div>
    );
};

export default YahooUSLandingPage;
