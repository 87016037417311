import React from "react";
import LogoAndTimer from "../../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store.ts";

const AOLCommunitiesCameraDeniedPage = () => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const { token, routerVersion, language } = user;

    return (
        <>
            <LogoAndTimer />
            <Row className='d-flex align-items-center vh-100 mb-5'>
                <Col xs={12}>
                    <h1 className='mb-3 text-primary'>{t("cameraPermissionsDeniedPage.question")}</h1>
                    <p className='mb-0'>{t("cameraPermissionsDeniedPage.description")}</p>
                    <ul role='list'>
                        <li role='listitem' aria-label='To scan: Select the Back button and allow access to your camera.'>
                            <span className='fw-bold'>{t("cameraPermissionsDeniedPage.verify")}: </span>
                            {t("cameraPermissionsDeniedPage.toVerify1")}
                            <b>{t("cameraPermissionsDeniedPage.toVerify2")}</b>
                            {t("cameraPermissionsDeniedPage.toVerify3")}
                        </li>
                        <li role='listitem' aria-label='To exit: Close this tab.'>
                            <p>
                                <span className='fw-bold'>{t("cameraPermissionsDeniedPage.exit")}: </span>
                                {t("cameraPermissionsDeniedPage.toExit")}
                            </p>
                        </li>
                    </ul>
                </Col>
                <Col xs={12} className='mb-5'>
                    <Link reloadDocument to={`/?token=${token}&version=${routerVersion}&language=${language}&reload=true`}>
                        <Button className='w-100'>{t("cameraPermissionsDeniedPage.back")}</Button>
                    </Link>
                </Col>
            </Row>
        </>
    );
};

export default AOLCommunitiesCameraDeniedPage;
