import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";

const MoneyTreeTimeoutPage = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <LogoAndTimer />
            <Stack gap={3} className='text-center mt-5'>
                <p>{t("moneytree.timeOut1")}</p>
                <p>{t("moneytree.timeOut2")}</p>
            </Stack>
        </Background>
    );
};

export default MoneyTreeTimeoutPage;
