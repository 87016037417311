import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// baseUrl for the server is set in the .env file
const apiVersion = window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION;
const baseUrl = window.__RUNTIME_CONFIG__.REACT_APP_SERVER_BASE_URL;
let routerVersion: string;

/** Conditional logic on the url in each mutation/query is not as clean as conditional logic on the baseUrl alone, however, it will allow us to move away from the technical verbiage debt of using the word 'ingest'. */
// https://localhost:8000
// https://localhost:8000/api/v1/submit/ipqs-trackerid

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const state: any = getState();
            const token = state?.user?.token;
            routerVersion = state?.user?.routerVersion;
            if (token) {
                headers.set("ingestToken", token);
                headers.set("transaction-id", token);
            }
            headers.set("routerVersion", routerVersion);
            headers.set("apiversion", apiVersion);
            return headers;
        },
    }),
    endpoints: (build) => ({
        logEvent: build.mutation({
            query: (params) => {
                const url = "/api/v1/log/event";
                return {
                    url,
                    method: "POST",
                    body: {
                        event: params.event, // Required status code from Customer Journey JSON doc.
                        action: params?.action, // Optional action message
                        clientTime: params.clientTime,
                    },
                };
            },
        }),
        updateDocumentType: build.mutation({
            query: (params) => {
                const url = "/api/v1/transaction/update-document-type";
                return {
                    url,
                    method: "POST",
                    body: {
                        newDocumentType: params.newDocumentType,
                    },
                };
            },
        }),
        ingestIpqsTrackerId: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/ipqs-trackerid" : "/ingest/ipqs-trackerId",
                    method: "POST",
                    body: {
                        ipqsTrackerId: params.ipqsTrackerId,
                        userAgent: params.userAgent,
                    },
                };
            },
        }),
        fetchUserConfig: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/user/get-config" : "/user/get-config",
                    method: "POST",
                    body: {
                        token: params.token,
                    },
                };
            },
        }),
        ingestFrontImage: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/front" : "/ingest/front-image",
                    method: "POST",
                    body: {
                        frontImage: params.frontImage,
                    },
                };
            },
        }),
        ingestBackImage: build.mutation({
            query: (params) => {
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/back" : "/ingest/back-image",
                    method: "POST",
                    body: {
                        backImage: params.backImage,
                    },
                };
            },
        }),
        ingestBarcodeData: build.mutation({
            query: (params) => {
                const url = "/ingest/barcode-data";
                return {
                    url: apiVersion === "1" ? "/api/v1/submit/barcode" : "/ingest/barcode-data",
                    method: "POST",
                    body: {
                        barcodeData: params.barcodeData,
                        backImage: params.backImage,
                    },
                };
            },
        }),
        fetchAuthIdUrl: build.mutation({
            query: (params) => {
                const url = "/service/get-authid-url";
                return {
                    url: apiVersion === "1" ? "/api/v1/selfie/start" : "/service/get-authid-url",
                    method: "POST",
                    body: {},
                };
            },
        }),
        fetchAndPostAuthIdResults: build.mutation({
            query: (params) => {
                const url = "/service/fetch-and-post-authid-results";
                return {
                    url: apiVersion === "1" ? "/api/v1/selfie/end" : "/service/fetch-and-post-authid-results",
                    method: "POST",
                    body: {},
                };
            },
        }),
        startJtTransaction: build.mutation({
            query: (params) => {
                const url = "/api/v1/transaction/start";
                return {
                    url: "/api/v1/transaction/start",
                    method: "POST",
                    body: {
                        signals: ["idcheck", "ocr_scan", "selfie"],
                    },
                };
            },
        }),
        startJtIDNDemoTransaction: build.mutation({
            query: (params) => {
                const url = "/api/v1/transaction/start";
                return {
                    url: "/api/v1/transaction/start",
                    method: "POST",
                    body: {
                        signals: ["idcheck", "ocr_scan", "selfie"],
                        urlLanguage: params.urlLanguage,
                    },
                };
            },
        }),
    }),
});

export const {
    useLogEventMutation,
    useFetchAuthIdUrlMutation,
    useFetchUserConfigMutation,
    useIngestBackImageMutation,
    useIngestFrontImageMutation,
    useIngestBarcodeDataMutation,
    useStartJtTransactionMutation,
    useStartJtIDNDemoTransactionMutation,
    useUpdateDocumentTypeMutation,
    useIngestIpqsTrackerIdMutation,
    useFetchAndPostAuthIdResultsMutation,
} = api;
