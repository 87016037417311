// bootstrap icon from: https://icons.getbootstrap.com/icons/check-circle-fill/
// todo: make this an svg

const TruistCheckMark = () => {
    return (
        <>
            <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M19.7296 41.8375L32.6352 54.7293L58.4189 28.9457M75 40C75 59.33 59.33 75 40 75C20.67 75 5 59.33 5 40C5 20.67 20.67 5 40 5C59.33 5 75 20.67 75 40Z'
                    stroke='#72569C'
                    stroke-width='5'
                    stroke-linejoin='round'
                />
            </svg>
        </>
    );
};
export default TruistCheckMark;
