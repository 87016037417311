import { useEffect } from "react";
import Background from "../../components/Background/Background.tsx";
import { useTranslation } from "react-i18next";
import CustJourneyCodes from "../../assets/CustomerJourneyCodes.json";
import LogoAndTimer from "../../components/LogoAndTimer/LogoAndTimer.tsx";
import { getCompanyConfig } from "../../utils/getCompanyConfig.ts";
import { useLogEventMutation } from "../../api/api.ts";
import createLogEventBody from "../../utils/createLogEventBody.js";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";

const TimeoutPage = () => {
    const { t } = useTranslation();
    const [logEvent] = useLogEventMutation();
    const errorRedirectUrl = useSelector((state: RootState) => state.user.errorRedirectUrl);
    const sessionStorageErrorUrl = sessionStorage.getItem("errorRedirectUrl");
    const errorLink = errorRedirectUrl ? errorRedirectUrl : sessionStorageErrorUrl !== "undefined" ? sessionStorageErrorUrl : null;

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.timeout.navToPage.status));
    }, []);

    if (errorLink) {
        window.location.reload();
        window.location.replace(errorLink);
    }

    const tokenTimeoutPage = getCompanyConfig("tokenTimeoutPage");

    if (tokenTimeoutPage) {
        return tokenTimeoutPage;
    }

    return (
        <>
            {!errorRedirectUrl && (
                <Background>
                    <LogoAndTimer />
                    <h3 className='text-center mt-5'>{t("timeoutPage")}</h3>
                </Background>
            )}
        </>
    );
};

export default TimeoutPage;
