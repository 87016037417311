/**
 * Description: Defines a Redux slice for AuthId-related state management.
 * This slice handles AuthId specific state which is separate from the user.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

/**
 * Set initial AuthId state
 */
type IpqsState = {
    hasProcessedIpqs: boolean;
};

const initialState: IpqsState = {
    hasProcessedIpqs: false,
};

/**
 * Create the AuthId slice
 */
const ipqsSlice = createSlice({
    name: "ipqs",
    initialState,
    reducers: {
        updateHasProcessedIpqs: (state, action: PayloadAction<boolean>) => {
            // console message index = 15
            state.hasProcessedIpqs = action.payload;
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    },
});

/**
 * Export AuthId slice actions
 */
export const { updateHasProcessedIpqs, reset: resetIpqsState } = ipqsSlice.actions;

/**
 * Export the reducer
 */
export default ipqsSlice.reducer;
