import Background from "../../../components/Background/Background.tsx";
import LogoAndTimer from "../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Button, Row, Stack } from "react-bootstrap";
import selfieImage from "../../../assets/selfie-image.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CustJourneyCodes from "../../../assets/CustomerJourneyCodes.json";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store.ts";
import { useLogEventMutation } from "../../../api/api.ts";
import createLogEventBody from "../../../utils/createLogEventBody.js";

const IDNDemoLandingPage = () => {
    const { t } = useTranslation();
    const [logEvent] = useLogEventMutation();

    const { search } = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(search);

    const user = useSelector((state: RootState) => state.user);
    const { captureRequirements: captureReqs } = user;

    // Fire status message when user navigates to this page
    useEffect(() => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.openTextLink.status));
    }, []);

    const clickStart = () => {
        logEvent(createLogEventBody(CustJourneyCodes.landing.clickStartButton.status));
        navigate(`/${captureReqs[0]}?${urlParams.toString()}`);
    };

    return (
        <Background>
            <LogoAndTimer />
            <Row className='my-4'>
                <img
                    className='col-xs-12 col-sm-10 col-md-7 w-50 d-block mx-auto'
                    src={selfieImage}
                    alt={"Man verifying identity on phone"}
                    aria-label={"Man verifying identity on phone"}
                />
            </Row>
            <Stack gap={2} className='mt-2 mt-md-3 d-flex justify-content-center align-items-center text-center'>
                <Button variant='primary' onClick={clickStart} className='col-7 col-md-5 p-2 p-md-3'>
                    {t("landingPage.start")}
                </Button>
                <div>
                    <h1 className='fw-bold'>{`3-${t("landingPage.step")}`}</h1>
                    <h2 className='text-center fw-bold'>{t("landingPage.identityValidation")}</h2>
                </div>
                <p>
                    {t("landingPage.privacyPolicyMessage")}{" "}
                    <a href={t("landingPage.privacyPolicyLink")} target='_blank'>
                        {t("landingPage.privacyPolicy")}
                    </a>
                    .
                </p>
                <p className='my-5'>{t("simulatorData")}</p>
            </Stack>
        </Background>
    );
};

export default IDNDemoLandingPage;
