import { useEffect, useState } from "react";
import calculateTimeUntilExpiration from "../../utils/calculateTimeUntilExpiration.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.js";

/**
 * @returns TSX Countdown Timer element
 */
interface ExpirationTimerProps {
    shouldRender: boolean;
}
const ExpirationTimer = ({ shouldRender }: ExpirationTimerProps) => {
    const { token, routerVersion, language } = useSelector((state: RootState) => state.user);
    const [ariaLive, setAriaLive] = useState<"off" | "assertive" | "polite" | undefined>("off");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const navigate = useNavigate();
    const expiredUrl = `/token-expired?token=${token}&version=${routerVersion}&language=${language}`;

    let intervalId: ReturnType<typeof setInterval> | undefined;

    // Function to update time
    const updateTime = () => {
        const { minutes, seconds } = calculateTimeUntilExpiration();
        setMinutes(minutes);
        setSeconds(seconds);
        if (minutes <= 0 && seconds <= 0) {
            clearInterval(intervalId);
            navigate(expiredUrl);
        }
    };

    useEffect(() => {
        if (minutes % 5 == 0 && seconds == 0) {
            setAriaLive("polite");
        } else {
            setTimeout(() => {
                setAriaLive("off");
            }, 25);
        }
    }, [ariaLive, minutes, seconds]);

    useEffect(() => {
        // Set interval to update the time every second
        intervalId = setInterval(updateTime, 1000);

        // Initial call to set the time
        updateTime();

        // Cleanup interval on component unmount
        return () => {
            if (intervalId !== undefined) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        <>
            {minutes >= 0 && seconds >= 0 && shouldRender && (
                <>
                    <h4
                        className='text-black bg-light p-2 rounded mb-0'
                        aria-label={`Time remaining to verify ${minutes.toString()} minutes ${seconds.toString()} seconds`}
                    >
                        {minutes?.toString()}:{seconds?.toString().padStart(2, "0")}
                    </h4>
                    <p
                        className='m-0 p-0'
                        aria-live={ariaLive}
                        aria-atomic='true'
                        aria-label={`Time remaining to verify ${minutes.toString()} minutes ${seconds.toString()} seconds`}
                    />
                </>
            )}
        </>
    );
};

export default ExpirationTimer;
