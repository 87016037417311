import React from "react";
import { Stack } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

const FifthThirdLandingPage = () => {
    const { t } = useTranslation();

    return (
        <Stack gap={1} className='text-center'>
            <h1 className='mt-4 mt-md-5 mb-md-5'>
                <Trans i18nKey='fifththird.landingText1'>
                    Bank a Fifth Third Better<sup className='fs-4'>{t("fifththird.landingText1")}</sup>.
                </Trans>
            </h1>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text2")}</p>
            <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text3")}</p>
        </Stack>
    );
};

export default FifthThirdLandingPage;
