type DebugOverlayProps = {
    debugCurrArea: any;
    debugCurrConfidence: any;
    minAreaPercent: any;
    maxAreaPercent: any;
    requiredConfidencePercent: any;
    debugNumGoodFrames: any;
    requiredFrameCount: any;
    debugCaptureFailReason: any;
};

const DebugOverlay = ({
    debugCurrArea: debug_curr_area,
    debugCurrConfidence: debug_curr_confidence,
    minAreaPercent,
    maxAreaPercent,
    requiredConfidencePercent,
    debugNumGoodFrames,
    requiredFrameCount,
    debugCaptureFailReason: debug_capture_fail_reason,
}: DebugOverlayProps) => {
    return (
        <>
            <div
                style={{
                    color: "white",
                    zIndex: 99999,
                    bottom: 10,
                    left: 4,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0rem",
                }}
            >
                <p>
                    Curr Area: {debug_curr_area.current} / {minAreaPercent.current} - {maxAreaPercent.current} (required)
                </p>
                <p>
                    Curr Confidence: {debug_curr_confidence.current} / {requiredConfidencePercent.current} (required)
                </p>
                <p>
                    No. Good Frames: {debugNumGoodFrames} / {requiredFrameCount.current} (required)
                </p>
                <p>Reason for not capturing: {debug_capture_fail_reason.current}</p>
            </div>
        </>
    );
};

export default DebugOverlay;
