import React from "react";
import { useTranslation } from "react-i18next";
import LogoAndTimer from "../../../../components/LogoAndTimer/LogoAndTimer.tsx";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLogEventMutation } from "../../../../api/api.ts";
import createLogEventBody from "../../../../utils/createLogEventBody";
import CustJourneyCodes from "../../../../assets/CustomerJourneyCodes.json";

const AOLCommunitiesBiometricsDeniedPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [logEvent] = useLogEventMutation();
    const clickBack = () => {
        logEvent(createLogEventBody(CustJourneyCodes.errors.biometricsDenied.clickBack.status));
        navigate(-1);
    };
    return (
        <>
            <LogoAndTimer />
            <Row className='vh-100 mt-5 justify-content-between'>
                <Col xs={12}>
                    <div className='mt-5'>
                        <h1 className='mb-3 text-primary'>{t("biometricsDeniedPage.question")}</h1>
                        <p className='mb-0'>{t("biometricsDeniedPage.description")}</p>
                        <ul role='list'>
                            <li role='listitem' aria-label={`${t("biometricsDeniedPage.truistAriaRec1")}`}>
                                <span className='fw-bold'>{t("biometricsDeniedPage.continue")}: </span>
                                {t("biometricsDeniedPage.toContinue1")}
                                <b>{t("biometricsDeniedPage.toContinue2")}</b>
                                {t("biometricsDeniedPage.toContinue3")}
                            </li>
                            <li role='listitem' aria-label={`${t("biometricsDeniedPage.truistAriaRec2")}`}>
                                <span className='fw-bold'>{t("biometricsDeniedPage.exit")}: </span>
                                {t("biometricsDeniedPage.toExit")}
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={12} className='mt-4 mb-5'>
                    <Button className='w-100' onClick={clickBack}>
                        {t("biometricsDeniedPage.back")}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default AOLCommunitiesBiometricsDeniedPage;
