import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorBoundaryWrapper from "../../components/ErrorBoundary/ErrorBoundaryWrapper.tsx";

type GenericCompanyLandingPageProps = {
    companyName: string;
};

function GenericCompanyLandingPage({ companyName }: GenericCompanyLandingPageProps) {
    const { t } = useTranslation();

    return (
        <ErrorBoundaryWrapper>
            <Stack gap={1} className='text-center'>
                <h1 className='mt-4 mt-md-5 mb-md-5'>
                    {t("genericLandingPageText.text1")}
                    {companyName}.
                </h1>
                <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text2")}</p>
                <p className='mt-4 mb-4 mt-md-5 mb-md-5'>{t("genericLandingPageText.text3")}</p>
            </Stack>
        </ErrorBoundaryWrapper>
    );
}

export default GenericCompanyLandingPage;
