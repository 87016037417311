const calculateTimeUntilExpiration = () => {
    const utcExpirationTime = sessionStorage.getItem("utcExpirationTime");

    if (!utcExpirationTime) {
        return { minutes: -1, seconds: 1 };
    }

    const expirationTime = new Date(utcExpirationTime);
    const currentTime = new Date();

    const timeDifferenceMs = expirationTime - currentTime;

    if (timeDifferenceMs <= 0) {
        return { minutes: -1, seconds: 1 };
    }

    const timeDifferenceSeconds = Math.floor(timeDifferenceMs / 1000);

    const minutes = Math.floor(timeDifferenceSeconds / 60);
    const seconds = timeDifferenceSeconds % 60;

    return { minutes, seconds };
};

export default calculateTimeUntilExpiration;
